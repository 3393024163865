import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    OutlinedInput,
    TextField,
    Alert,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
} from "@mui/material";
import { UserModel, GroupModel } from "models/Models";
import { NavBar } from "components/Components";
import { NetworkAction, ProcessAction } from "redux/Actions";
import { GroupRequest } from "api/requests/Requests";
import { DbConstants } from "constants/DbConstants";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupEdit.scss";

type Props = {
    loginUser: UserModel | null;
};

const GroupEdit: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupEdit");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { groupId } = useParams<{ groupId: string }>();
        const GROUP_PUBLIC_SETTING_PUBLIC = "1";
        const GROUP_PUBLIC_SETTING_PRIVATE = "2";
        const GROUP_JOIN_SYSTEM_AUTO_JOIN = "1";
        const GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL = "2";

        /***** useRef */
        const processing = React.useRef(false);

        /***** useState */
        const [group, setGroup] = React.useState<GroupModel | null>();
        const [groupName, setGroupName] = React.useState<string>();
        const [publicSetting, setPublicSetting] = React.useState<string>(
            GROUP_PUBLIC_SETTING_PUBLIC
        );
        const [joinSystem, setJoinSystem] = React.useState<string>(
            GROUP_JOIN_SYSTEM_AUTO_JOIN
        );
        const [groupProfile, setGroupProfile] = React.useState<string>();
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        const [errMessage, setErrMessage] = React.useState<string>("");
        const [completeMessage, setCompleteMessage] =
            React.useState<string>("");
        const [initialDataLoaded, setInitialDataLoaded] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ編集",
                path: window.location.pathname,
            });
        }, []);        
        React.useEffect(() => {
            if (!initialDataLoaded && props.loginUser != null) {
                (async () => {
                    const group = await getGroup();
                    if (group != null) {
                        if (
                            props.loginUser == null ||
                            props.loginUser.id !== group.ownerId
                        ) {
                            navigate("/");
                            return;
                        }
                        setGroup(group);
                        setGroupStatus(group);
                        setInitialDataLoaded(true);
                    }
                })();
            }
        }, [props.loginUser, group]);

        /**
         * グループ保存ボタン押下時
         */
        function onClickSave() {
            if (validate()) {
                executeSaveGroup();
            }
        }

        /**
         * グループ保存
         */
        async function executeSaveGroup() {
            if (group == null) {
                return;
            }
            setOpenConfirmDialog(false);
            if (props.loginUser == null) {
                return;
            }
            if (processing.current) {
                return;
            }
            processing.current = true;
            if (groupName != null) {
                group.name = groupName;
            }
            switch (publicSetting) {
                case GROUP_PUBLIC_SETTING_PUBLIC:
                    group.open = DbConstants.GROUP_PUBLIC;
                    break;
                case GROUP_PUBLIC_SETTING_PRIVATE:
                    group.open = DbConstants.GROUP_PRIVATE;
                    break;
                default:
                    processing.current = false;
                    return;
            }
            switch (joinSystem) {
                case GROUP_JOIN_SYSTEM_AUTO_JOIN:
                    group.autoJoin = DbConstants.GROUP_AUTO_JOIN;
                    break;
                case GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL:
                    group.autoJoin = DbConstants.GROUP_INDIVIDUAL_APPROVAL;
                    break;
                default:
                    processing.current = false;
                    return;
            }
            dispatch(
                ProcessAction({ processing: true, message: "msg_processing" })
            );

            if (groupProfile != null) {
                group.profile = groupProfile;
            }
            const result = await GroupRequest.save(props.loginUser, group);
            dispatch(ProcessAction({ processing: false, message: "" }));

            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                setErrMessage(intl.formatMessage({ id: "err_save_group" }));
                processing.current = false;
                return;
            }
            setCompleteMessage(intl.formatMessage({ id: "msg_complete_save" }));
            processing.current = false;
            // navigate(-2);
        }

        /**
         * バリデーション
         * @returns
         */
        function validate(): boolean {
            if (groupName == null) {
                const errMessage = intl.formatMessage({
                    id: "err_empty_group_name",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (publicSetting == null || publicSetting.length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_not_selected",
                });
                setErrMessage(errMessage);
                return false;
            }
            if (joinSystem == null || joinSystem.length === 0) {
                const errMessage = intl.formatMessage({
                    id: "err_not_selected",
                });
                setErrMessage(errMessage);
                return false;
            }
            setErrMessage("");
            return true;
        }

        /**
         * グループ情報をセット
         * @param _group
         */
        function setGroupStatus(_group: GroupModel) {
            if (_group.name != null) {
                setGroupName(_group.name);
            }
            if (_group.open != null) {
                if (_group.open === DbConstants.GROUP_PUBLIC) {
                    setPublicSetting(GROUP_PUBLIC_SETTING_PUBLIC);
                } else if (_group.open === DbConstants.GROUP_PRIVATE) {
                    setPublicSetting(GROUP_PUBLIC_SETTING_PRIVATE);
                }
            }
            if (_group.autoJoin != null) {
                if (_group.autoJoin === DbConstants.GROUP_AUTO_JOIN) {
                    setJoinSystem(GROUP_JOIN_SYSTEM_AUTO_JOIN);
                } else if (
                    _group.autoJoin === DbConstants.GROUP_INDIVIDUAL_APPROVAL
                ) {
                    setJoinSystem(GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL);
                }
            }
            if (_group.profile != null) {
                setGroupProfile(_group.profile);
            }
        }
        /**
         * グループ詳細取得
         */
        async function getGroup() {
            if (groupId != null) {
                const result = await GroupRequest.getDetail(Number(groupId));
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }
                if (
                    result.rtnCd != null &&
                    result.rtnCd === 0 &&
                    result.group != null
                ) {
                    return result.group;
                }
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper GroupCreate">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "title_group_edit" })}
                />

                {
                    // エラーメッセージエリア
                }
                {errMessage.length > 0 && (
                    <Alert
                        className="error-message"
                        onClose={() => {
                            setErrMessage("");
                        }}
                        severity="error"
                    >
                        {errMessage}
                    </Alert>
                )}
                {
                    // 完了メッセージエリア
                }
                {completeMessage.length > 0 && (
                    <Alert
                        className="complete-message"
                        onClose={() => {
                            setCompleteMessage("");
                        }}
                        severity="success"
                    >
                        {completeMessage}
                    </Alert>
                )}
                {
                    // ガイド文言
                }
                <Box
                    component="h1"
                    className="page-title"
                    display={{
                        xs: "none",
                        sm: "block",
                    }}
                >
                    <FormattedMessage id={"title_group_edit2"} />
                </Box>

                {initialDataLoaded && (
                    <div className="contents-wrapper">
                        <div className="info-area">
                            {
                                // グループ名
                            }
                            <div className="group-name-area">
                                <label className="label">
                                    <FormattedMessage id={"group_name"} />
                                </label>
                                <OutlinedInput
                                    className="group-name-value"
                                    fullWidth
                                    label=""
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                    value={groupName}
                                    onChange={(e) => {
                                        setGroupName(e.target.value);
                                    }}
                                />
                            </div>
                            <Divider className="divider" />
                            {
                                // 公開設定
                            }
                            <div className="public-setting-area">
                                <label className="label">
                                    <FormattedMessage
                                        id={"group_public_setting"}
                                    />
                                </label>
                                <div className="value-area">
                                    <RadioGroup
                                        className="radio-group"
                                        name="rdo-public-setting"
                                        value={publicSetting}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setPublicSetting(e.target.value);
                                        }}
                                    >
                                        {
                                            // 公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        GROUP_PUBLIC_SETTING_PUBLIC
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "group_public",
                                            })}
                                        />
                                        {
                                            // 非公開
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        GROUP_PUBLIC_SETTING_PRIVATE
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "group_private",
                                            })}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                            <Divider className="divider" />
                            {
                                // 加入方式
                            }
                            <div className="join-system-area">
                                <label className="label">
                                    <FormattedMessage
                                        id={"group_join_system"}
                                    />
                                </label>
                                <div className="value-area">
                                    <RadioGroup
                                        className="radio-group"
                                        name="rdo-join-system"
                                        value={joinSystem}
                                        onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            setJoinSystem(e.target.value);
                                        }}
                                    >
                                        {
                                            // 自動承認制
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        GROUP_JOIN_SYSTEM_AUTO_JOIN
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "join_auto_approval",
                                            })}
                                        />
                                        {
                                            // 個別承認制
                                        }
                                        <FormControlLabel
                                            className="radio-item"
                                            control={
                                                <Radio
                                                    value={
                                                        GROUP_JOIN_SYSTEM_INDIVIDUAL_APPROVAL
                                                    }
                                                />
                                            }
                                            label={intl.formatMessage({
                                                id: "join_individual_approval",
                                            })}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                            <Divider className="divider" />
                            {
                                // グループプロフィール
                            }
                            <div className="profile-area">
                                <div className="label">
                                    <FormattedMessage id={"group_profile"} />
                                </div>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: "#ffffff",
                                        fontSize: "1rem",
                                        borderRadius: "4px",
                                        "& .MuiInputBase-root": {
                                            padding: "5px",
                                        },
                                    }}
                                    label=""
                                    type="text"
                                    multiline={true}
                                    maxRows={30}
                                    minRows={8}
                                    inputProps={{
                                        maxLength: 500,
                                    }}
                                    value={groupProfile}
                                    onChange={(e) => {
                                        setGroupProfile(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        {
                            // ボタンエリア
                        }
                        {props.loginUser != null && (
                            <div className="button-area">
                                {
                                    // グループ保存ボタン
                                }
                                <Button
                                    className="app-button button"
                                    variant="text"
                                    onClick={onClickSave}
                                >
                                    <FormattedMessage id={"btn_save2"} />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                <Dialog
                    className="dialog"
                    open={openConfirmDialog}
                    onClose={() => {
                        setOpenConfirmDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        className="dialog-title"
                        id="alert-dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"confirm_group_create"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            className="dialog-button"
                            onClick={executeSaveGroup} 
                            color="primary">
                            <FormattedMessage id={"btn_yes2"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default GroupEdit;
