import React from "react";
import {
    Box,
    Alert,
    Zoom,
    Fab,
    useScrollTrigger,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { NetworkAction, ProcessAction } from "redux/Actions";
import { AppConstants } from "constants/AppConstants";
import { ResponseRequest, ThreadRequest } from "api/requests/Requests";
import {
    UserModel,
    ThreadModel,
    ResponseModel,
    CategoryModel,
} from "models/Models";
import { NavBar, ResponseListItem } from "components/Components";
import { pushDataLayer } from "gtm/gtm"
import "styles/components/ResponseListComponent.scss";

type Props = {
    loginUser: UserModel | null;
    category: CategoryModel;
    langCd: string;
    timeDifference: number;
};

export const ResponseListComponent: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ ResponseListComponent IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const { threadId } = useParams<{ threadId: string }>();

        /***** useRef */
        const isUnderProcessing = React.useRef<boolean>(false);

        /***** useState */
        // スレッド
        const [thread, setThread] = React.useState<ThreadModel | null>();
        // レスポンスリスト
        const [lstResponse, setResponseList] = React.useState<ResponseModel[]>(
            []
        );
        const [loadCompleted, setLoadCompleted] =
            React.useState<boolean>(false);
        // 本文
        const [body, setBody] = React.useState<string>("");
        // エラーメッセージ
        const [errMessage, setErrMessage] = React.useState<string>("");
        // ボタンラベル
        const [btnPostLabel, setBtnPostLabel] = React.useState<string>("");
        // ボタン活性化フラグ
        const [btnPostDisabled, setBtnPostDisabled] =
            React.useState<boolean>(false);
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        // ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>("");

        const trigger = useScrollTrigger({
            target: window,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            window.scrollTo({ top: 0 });
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "レスポンス一覧(掲示板)",
                path: window.location.pathname,
            });
            setPostButtonLabel("btn_post");

            (async () => {
                dispatch(
                    ProcessAction({
                        processing: true,
                        message: "msg_loading",
                    })
                );
                const thread = await getThread();
                if (thread == null) {
                    navigate(-1);
                    return;
                }
                await fetchResponseList(thread);
                dispatch(ProcessAction({ processing: false, message: "" }));
            })();
            return () => {};
        }, []);

        /**
         * ダイアログ閉じる
         */
        function handleDialogClose() {
            setOpenDialog(false);
        }

        /**
         * 投稿ボタンのラベル設定
         * @param labelId
         */
        const setPostButtonLabel = (labelId: string) => {
            const btnLabel = intl.formatMessage({
                id: labelId,
            });
            setBtnPostLabel(btnLabel);
        };

        /**
         * 投稿ボタンクリック時
         * @returns
         */
        const onClickPost = async () => {
            if (validate()) {
                post();
            }
        };

        /**
         * バリデーション
         * @returns
         */
        function validate(): boolean {
            if (props.loginUser == null) {
                setDialogMessage(
                    intl.formatMessage({ id: "msg_login_needed" })
                );
                setOpenDialog(true);
                return false;
            }
            if (body.trim().length === 0) {
                setDialogMessage(
                    intl.formatMessage({ id: "err_empty_message" })
                );
                setOpenDialog(true);
                return false;
            }
            return true;
        }
        /**
         * 投稿
         * @returns
         */
        async function post() {
            if (
                props.loginUser == null ||
                thread == null ||
                thread.id == null
            ) {
                return;
            }
            if (isUnderProcessing.current) {
                return;
            }
            isUnderProcessing.current = true;
            setBtnPostDisabled(true);
            setPostButtonLabel("msg_processing");
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_processing",
                })
            );
            const result = await ResponseRequest.post(
                thread.id,
                body,
                props.loginUser
            );
            try {
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    setBtnPostDisabled(true);
                    setPostButtonLabel("msg_processing");
                    return null;    
                }
                if (result.rtnCd == null || result.rtnCd < 0) {
                    const errMessage = intl.formatMessage({
                        id: "err_failed_to_post_message_board",
                    });
                    setErrMessage(errMessage);
                    return;
                }
                if (result.response != null) {
                    lstResponse.push(result.response);
                    setResponseList(lstResponse);
                }
                setBody("");
            } catch (e) {
            } finally {
                setBtnPostDisabled(false);
                setPostButtonLabel("btn_post");
                dispatch(ProcessAction({ processing: false, message: "" }));
                isUnderProcessing.current = false;
            }
        }

        /**
         * スレッド取得
         * @returns
         */
        async function getThread() {
            if (threadId != null) {
                const result = await ThreadRequest.getThread(Number(threadId));
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return null;
                }

                if (result.thread != null) {
                    setThread(result.thread);
                    return result.thread;
                }
            }
            return null;
        }
        /**
         * レスポンスリスト取得
         * @param index
         */
        async function fetchResponseList(thread: ThreadModel | null) {
            if (thread == null || thread.id == null) {
                return;
            }
            let result = await ResponseRequest.getList(thread.id);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;    
            }
            // レスポンスリスト
            if (result.lstResponse != null) {
                setResponseList(result.lstResponse);
            }
            setLoadCompleted(true);
        }

        return (
            <Box
                className="component ResponseListComponent"
                sx={{
                    position: "relative",
                    margin: "auto",
                }}
            >
                {thread != null && thread.title != null && (
                    <>
                        {loadCompleted && props.langCd != null && (
                            <div className="contents-wrapper">
                                <div className="thread-title">
                                    {thread.title}
                                </div>
                                <ResponseListItem
                                    index={1}
                                    user={thread.user}
                                    body={thread.body}
                                    createdAt={thread.createdAt}
                                    langCd={props.langCd}
                                    timeDifference={props.timeDifference}
                                />
                                {lstResponse.map(
                                    (response: ResponseModel, index) => {
                                        return (
                                            <React.Fragment
                                                key={`res_${response.id}_${
                                                    index + 1
                                                }`}
                                            >
                                                <ResponseListItem
                                                    index={index + 2}
                                                    user={response.user}
                                                    body={response.body}
                                                    createdAt={
                                                        response.createdAt
                                                    }
                                                    langCd={props.langCd}
                                                    timeDifference={
                                                        props.timeDifference
                                                    }
                                                />
                                            </React.Fragment>
                                        );
                                    }
                                )}
                                {
                                    // エラーメッセージエリア
                                }
                                {errMessage.length > 0 && (
                                    <Alert
                                        severity="error"
                                        onClose={() => {
                                            setErrMessage("");
                                        }}
                                    >
                                        {errMessage}
                                    </Alert>
                                )}
                                {lstResponse != null &&
                                    lstResponse.length <
                                        AppConstants.MAX_RESPONSE_COUNT && (
                                        <div className="input-area">
                                            {
                                                // 本文
                                            }
                                            <FormControl
                                                className="body-area"
                                                variant="standard"
                                            >
                                                <label
                                                    className="body-label"
                                                    htmlFor="txtBody"
                                                >
                                                    <FormattedMessage
                                                        id={"message"}
                                                    />
                                                </label>
                                                <TextField
                                                    className="body-value"
                                                    fullWidth
                                                    variant="outlined"
                                                    id="txtBody"
                                                    label=""
                                                    type="text"
                                                    multiline={true}
                                                    maxRows={30}
                                                    minRows={6}
                                                    inputProps={{
                                                        maxLength: 2000,
                                                    }}
                                                    value={body}
                                                    onChange={(e) => {
                                                        setBody(e.target.value);
                                                    }}
                                                />
                                            </FormControl>
                                            {
                                                // ボタンエリア
                                            }
                                            <div className="button-area">
                                                <Button
                                                    className={
                                                        btnPostDisabled
                                                            ? "app-button button disabled"
                                                            : "app-button button"
                                                    }
                                                    variant="text"
                                                    disabled={btnPostDisabled}
                                                    onClick={onClickPost}
                                                >
                                                    {btnPostLabel}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        )}
                        <Dialog
                            className="dialog"
                            open={openDialog}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    padding: {
                                        xs: "5px",
                                        sm: "10px",
                                    },
                                    paddingTop: {
                                        xs: "5px !important",
                                        sm: "10px !important",
                                    },
                                }}
                            >
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    onClick={handleDialogClose}
                                    color="primary"
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* <Zoom in={trigger}>
                            <Box
                                role="presentation"
                                sx={{
                                    position: "fixed",
                                    bottom: {
                                        xs: 100,
                                        sm: 32,
                                    },
                                    right: 10,
                                    zIndex: 100,
                                }}
                            >
                                <Fab
                                    onClick={scrollToTop}
                                    color="primary"
                                    size="small"
                                    aria-label="scroll back to top"
                                >
                                    <KeyboardArrowUp />
                                </Fab>
                            </Box>
                        </Zoom> */}
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.category !== nextProps.category) {
            return false;
        }
        if (prevProps.category != null && nextProps.category != null) {
            if (prevProps.category.id !== nextProps.category.id) {
                return false;
            }
        }
        if (prevProps.langCd !== nextProps.langCd) {
            return false;
        }
        if (prevProps.timeDifference !== nextProps.timeDifference) {
            return false;
        }

        return true;
    }
);
