import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Link,
    Divider,
    Button,
    OutlinedInput,
    Select,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SelectChangeEvent } from "@mui/material/Select";
import { useDropzone } from "react-dropzone";
import {
    NavBar,
    ProfileDialog,
    SelectTagDialog,
    SelectCountryDialog,
} from "components/Components";
import { DbConstants, MYPAGE_ITEM } from "constants/Constants";
import {
    ProcessAction,
    UserAction,
    SelectedItemAction,
    TagListAction,
    WorkingItemAction,
    CountryListAction,
    UnreadCountAction,
    EmitterAction,
    NetworkAction,
} from "redux/Actions";
import {
    getTagList,
    getWorkingProfile,
    getUserTagList,
    getUserCustomTagList,
    getCountryList,
    getLanguageCode,
} from "redux/Selectors";
import Utility from "utils/Utility";
import { UserRequest, TagRequest, CountryRequest } from "api/requests/Requests";
import {
    UserModel,
    TagModel,
    CustomTagModel,
    CountryModel,
} from "models/Models";
import { AppServer } from "socket/AppServer";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/MyPage.scss";

type Props = {
    loginUser: UserModel;
};

const MyPage: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ MyPage IN");
        /***** 定数 */
        const intl = useIntl();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        let wkSelectedTagList = getUserTagList(selector);
        let wkSelectedCustomTagList = getUserCustomTagList(selector);
        let wkProfileHtml = getWorkingProfile(selector);
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const gotMyData = React.useRef<boolean>(false);
        const retainEdittedData = React.useRef<boolean>(false);
        const isUnderProcess = React.useRef<boolean>(false);

        /***** useState */
        // ユーザ名
        const [userName, setUserName] = React.useState<string>("");
        const [editModeUserName, setEditModeUserName] =
            React.useState<boolean>(false);
        const [saveUserNameProcessing, setSaveUserNameProcessing] =
            React.useState<boolean>(false);
        // プロフィール画像
        const [profImage, setProfImage] = React.useState<File>();
        const [previewImagePath, setPreviewImagePath] =
            React.useState<string>("");
        const [editModeProfImage, setEditModeProfImage] =
            React.useState<boolean>(false);
        const [saveProfImageProcessing, setSaveProfImageProcessing] =
            React.useState<boolean>(false);

        const onDrop = React.useCallback(async (files: File[]) => {
            // Do something with the files
            const buffer = await files[0].arrayBuffer();
            const blob = new File([buffer], files[0].name, {
                type: files[0].type,
            });
            setProfImage(blob);
            const dataUrl = URL.createObjectURL(blob);
            // setProfImage(files[0]);
            // const dataUrl = URL.createObjectURL(files[0]);
            setPreviewImagePath(dataUrl);
        }, []);
        const { getRootProps, getInputProps } = useDropzone({
            onDrop,
            accept: {
                "image/*": [".jpeg", ".png"],
            },
            multiple: false,
        });
        // プロフィール
        const [openDialogProfile, setOpenDialogProfile] =
            React.useState<boolean>(false);
        const [profileHtml, setProfileHtml] = React.useState<string>("");
        // 性別
        const [gender, setGender] = React.useState<number>(
            DbConstants.GENDER_NONE
        );
        const [editModeGender, setEditModeGender] =
            React.useState<boolean>(false);
        const [saveGenderProcessing, setSaveGenderProcessing] =
            React.useState<boolean>(false);
        // 年代
        const [age, setAge] = React.useState<number>(DbConstants.AGE_NONE);
        const [editModeAge, setEditModeAge] = React.useState<boolean>(false);
        const [saveAgeProcessing, setSaveAgeProcessing] =
            React.useState<boolean>(false);
        // 属性
        const [attribute, setAttribute] = React.useState<number>(
            DbConstants.ATTRIBUTE_NONE
        );
        const [editModeAttribute, setEditModeAttribute] =
            React.useState<boolean>(false);
        const [saveAttributeProcessing, setSaveAttributeProcessing] =
            React.useState<boolean>(false);
        // 地域
        const [region, setRegion] = React.useState<string>("");
        const [editModeRegion, setEditModeRegion] =
            React.useState<boolean>(false);
        const [saveRegionProcessing, setSaveRegionProcessing] =
            React.useState<boolean>(false);
        // 興味、関心
        const [openDialogTag, setOpenDialogTag] =
            React.useState<boolean>(false);
        const [lstTag, setTagList] = React.useState<TagModel[]>([]);
        const [lstSelectedTag, setSelectedTagList] =
            React.useState<TagModel[]>();
        const [lstSelectedCustomTag, setSelectedCustomTagList] =
            React.useState<CustomTagModel[]>();
        // 国
        const [openDialogCountry, setOpenDialogCountry] =
            React.useState<boolean>(false);
        const [country, setCountry] = React.useState<CountryModel>();
        const [lstCountry, setCountryList] = React.useState<CountryModel[]>(
            getCountryList(selector)
        );
        // アカウント
        const [email, setEmail] = React.useState<string>();
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        const [dialogMessage, setDialogMessage] = React.useState<string>("");
        const [openResultDialog, setOpenResultDialog] =
            React.useState<boolean>(false);
        const [resultMessage, setResultMessage] = React.useState<string>("");

        /***** useEffect */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "マイページ",
                path: window.location.pathname,
            });

            return () => {
                if (
                    retainEdittedData == null ||
                    retainEdittedData.current == null ||
                    !retainEdittedData.current
                ) {
                    dispatch(
                        WorkingItemAction({
                            profile: null,
                        })
                    );
                }
            };
        }, []);
        React.useEffect(() => {
            if (props.loginUser == null) {
                return;
            }
            (async () => {
                if (gotMyData.current === false) {
                    await fetchMyData();
                    return;
                }
                if (lstTag == null) {
                    await fetchTagList();
                }
                // 国
                let wkCountryList = lstCountry;
                if (wkCountryList == null) {
                    let tmpCountryList = await fetchCountryList();
                    if (tmpCountryList != null) {
                        wkCountryList = tmpCountryList;
                        setCountryList(wkCountryList);
                    }
                }
                if (wkCountryList != null) {
                    let wkCountryCode = props.loginUser.countryCd;
                    if (wkCountryCode != null) {
                        wkCountryList.forEach((wkCountry) => {
                            if (wkCountry.code === wkCountryCode) {
                                setCountry(wkCountry);
                            }
                        });
                    }
                }
                // タグ
                if (
                    props.loginUser.lstSelectedTag != null &&
                    props.loginUser.lstSelectedTag.length > 0
                ) {
                    const lstSelectedTag = props.loginUser.lstSelectedTag.map(
                        (selectedTag) => {
                            selectedTag.selected = true;
                            return selectedTag;
                        }
                    );
                    setSelectedTagList(lstSelectedTag);
                    dispatch(
                        SelectedItemAction({
                            lstTag: lstSelectedTag,
                        })
                    );
                }
                // カスタムタグ
                if (
                    props.loginUser.lstCustomTag != null &&
                    props.loginUser.lstCustomTag.length > 0
                ) {
                    const lstCustomTag = props.loginUser.lstCustomTag.map(
                        (customTag) => {
                            customTag.selected = true;
                            return customTag;
                        }
                    );
                    setSelectedCustomTagList(lstCustomTag);
                    dispatch(
                        SelectedItemAction({
                            lstCustomTag: lstCustomTag,
                        })
                    );
                }
                // プロフィール
                if (props.loginUser.profile != null) {
                    setProfileHtml(props.loginUser.profile);
                }
            })();
        }, [props.loginUser]);
        React.useEffect(() => {
            if (wkSelectedTagList != null && wkSelectedTagList.length > 0) {
                setSelectedTagList(wkSelectedTagList);
            } else {
                setSelectedTagList([]);
            }
        }, [wkSelectedTagList]);
        React.useEffect(() => {
            if (
                wkSelectedCustomTagList != null &&
                wkSelectedCustomTagList.length > 0
            ) {
                setSelectedCustomTagList(wkSelectedCustomTagList);
            } else {
                setSelectedCustomTagList([]);
            }
        }, [wkSelectedCustomTagList]);
        React.useEffect(() => {
            if (wkProfileHtml == null) {
                setProfileHtml("");
            } else {
                setProfileHtml(wkProfileHtml);
            }
        }, [wkProfileHtml]);

        /**
         * ユーザ名編集クリック時
         */
        function onClickEditUserName() {
            if (props.loginUser.name == null) {
                setUserName("");
            } else {
                setUserName(props.loginUser.name);
            }
            setEditMode(MYPAGE_ITEM.USERNAME, true);
        }
        function onClickCancelEditUserName() {
            setEditMode(MYPAGE_ITEM.USERNAME, false);
        }
        function onClickSaveEditUserName() {
            saveUserName();
        }

        /**
         * プロフィール画像編集クリック時
         */
        function onClickEditProfilePicture() {
            setEditMode(MYPAGE_ITEM.PROFILE_PICTURE, true);
        }
        function onClickCancelEditProfilePicture() {
            setEditMode(MYPAGE_ITEM.PROFILE_PICTURE, false);
        }
        function onClickSaveEditProfilePicture() {
            saveProfileImage();
        }

        /**
         * プロフィール編集クリック時
         */
        function onClickEditProfile() {
            dispatch(
                WorkingItemAction({
                    profile: profileHtml,
                })
            );
            if (isSizeXS) {
                retainEdittedData.current = true;
                navigate("/profile");
            } else {
                setOpenDialogProfile(true);
            }
        }
        function onOkProfileDialog(_profileHtml: string, _profile: string) {
            setOpenDialogProfile(false);
        }
        function onCancelProfileDialog() {
            setOpenDialogProfile(false);
        }
        function onCloseProfileDialog() {
            setOpenDialogProfile(false);
        }

        /**
         * 性別編集クリック時
         */
        function onClickEditGender() {
            if (props.loginUser.gender != null) {
                setGender(props.loginUser.gender);
            }
            setEditMode(MYPAGE_ITEM.GENDER, true);
        }
        function onClickCancelEditGender() {
            setEditMode(MYPAGE_ITEM.GENDER, false);
        }
        function onClickSaveEditGender() {
            saveGender();
        }

        /**
         * 年代編集クリック時
         */
        function onClickEditAge() {
            if (props.loginUser.age != null) {
                setAge(props.loginUser.age);
            }
            setEditMode(MYPAGE_ITEM.AGE, true);
        }
        function onClickCancelEditAge() {
            setEditMode(MYPAGE_ITEM.AGE, false);
        }
        function onClickSaveEditAge() {
            saveAge();
        }

        /**
         * エリア編集クリック時
         */
        function onClickEditRegion() {
            if (props.loginUser.region != null) {
                setRegion(props.loginUser.region);
                setEditMode(MYPAGE_ITEM.REGION, true);
            }
        }
        function onClickCancelEditRegion() {
            setEditMode(MYPAGE_ITEM.REGION, false);
        }
        function onClickSaveEditRegion() {
            saveRegion();
        }

        /**
         * 属性編集クリック時
         */
        function onClickEditAttribute() {
            if (props.loginUser.attribute != null) {
                setAttribute(props.loginUser.attribute);
                setEditMode(MYPAGE_ITEM.ATTRIBUTE, true);
            }
        }
        function onClickCancelEditAttribute() {
            setEditMode(MYPAGE_ITEM.ATTRIBUTE, false);
        }
        function onClickSaveEditAttribute() {
            saveAttribute();
        }

        /**
         * タグ編集クリック時
         */
        function onClickEditTag() {
            if (isSizeXS) {
                navigate("/select/tag");
            } else {
                setOpenDialogTag(true);
            }
        }
        function onOkTagDialog() {
            setOpenDialogTag(false);
        }
        function onCancelTagDialog() {
            setOpenDialogTag(false);
        }
        function onCloseTagDialog() {
            setOpenDialogTag(false);
        }

        /**
         * ギャラリー編集クリック時
         */
        function onClickEditGallery() {
            navigate(`/mypage/album`);
        }

        /**
         * フォロー編集クリック時
         */
        function onClickManageFollow() {
            navigate(`/mypage/follow`);
        }

        /**
         * フォロワー編集クリック時
         */
        function onClickManageFollower() {
            navigate(`/mypage/follower`);
        }

        /**
         * ブロック編集クリック時
         */
        function onClickManageBlock() {
            navigate(`/mypage/block`);
        }

        /**
         * グループ編集クリック時
         */
        function onClickManageGroup() {
            navigate(`/mypage/group`);
        }

        /**
         * 国編集クリック時
         */
        function onClickEditCountry() {
            if (isSizeXS) {
                navigate("/select/country");
            } else {
                setOpenDialogCountry(true);
            }
        }
        function onOkCountryDialog() {
            setOpenDialogCountry(false);
        }
        function onCancelCountryDialog() {
            setOpenDialogCountry(false);
        }
        function onCloseCountryDialog() {
            setOpenDialogCountry(false);
        }

        /**
         * アカウント削除クリック時
         */
        function onClickDeleteAccount() {
            const message1 = intl.formatMessage({
                id: "guide_account_delete",
            });
            const message2 = intl.formatMessage({
                id: "msg_delete_account_confirm",
            });
            const message = `${message1}<br />${message2}`;
            setDialogMessage(message);
            setOpenConfirmDialog(true);
        }

        /**
         * ユーザ詳細画面へ遷移
         */
        async function goToProfPage() {
            if (props.loginUser != null) {
                const key = Utility.getUserKey(props.loginUser);
                if (key.length > 0) {
                    dispatch(
                        SelectedItemAction({ userId: props.loginUser.id })
                    );
                    setTimeout(function () {
                        navigate(`/users/${key}/profile`);
                    });
                }
            }
        }

        /**
         * 保存前処理
         * @param item
         * @returns
         */
        function beforeSaveCommonProcess(item: MYPAGE_ITEM): UserModel | null {
            if (props.loginUser != null) {
                let updateUser = new UserModel();
                updateUser.id = props.loginUser.id;
                updateUser.createdAt = props.loginUser.createdAt;
                updateUser.bearerToken = props.loginUser.bearerToken;
                switch (item) {
                    // ユーザ名
                    case MYPAGE_ITEM.USERNAME:
                        updateUser.name = userName;
                        break;
                    // 性別
                    case MYPAGE_ITEM.GENDER:
                        updateUser.gender = gender;
                        break;
                    // 年代
                    case MYPAGE_ITEM.AGE:
                        updateUser.age = age;
                        break;
                    // 属性
                    case MYPAGE_ITEM.ATTRIBUTE:
                        updateUser.attribute = attribute;
                        break;
                    // プロフィール画像
                    case MYPAGE_ITEM.PROFILE_PICTURE:
                        if (profImage != null) {
                            updateUser.profileImage = profImage;
                        }
                        break;
                    // 地域
                    case MYPAGE_ITEM.REGION:
                        updateUser.region = region;
                        break;
                    default:
                        return null;
                }
                return updateUser;
            }
            return null;
        }
        /**
         * 保存後処理
         * @param user
         * @param token
         */
        async function afterSaveCommonProcess(user: UserModel) {
            if (props.loginUser == null) {
                return;
            }
            if (user.bearerToken == null) {
                user.bearerToken = props.loginUser.bearerToken;
            }
            dispatch(
                UserAction({
                    loginUser: user,
                })
            );
        }
        /**
         * ユーザ名保存
         */
        async function saveUserName() {
            const updateUser = beforeSaveCommonProcess(MYPAGE_ITEM.USERNAME);
            if (updateUser == null || saveUserNameProcessing) {
                return;
            }
            setSaveUserNameProcessing(true);
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    afterSaveCommonProcess(result.user);
                }
            }
            setEditMode(MYPAGE_ITEM.USERNAME, false);
            setSaveUserNameProcessing(false);
        }
        /**
         * プロフィール画像保存
         * @returns
         */
        async function saveProfileImage() {
            if (
                profImage == null ||
                previewImagePath == null ||
                previewImagePath.length === 0
            ) {
                return;
            }
            const updateUser = beforeSaveCommonProcess(
                MYPAGE_ITEM.PROFILE_PICTURE
            );
            if (updateUser == null || saveProfImageProcessing) {
                return;
            }
            setSaveProfImageProcessing(true);
            dispatch(
                ProcessAction({
                    processing: true,
                    message: "msg_processing",
                })
            );
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistration().then((registration) => {
                        if (registration) {
                        registration.active?.postMessage({
                            type: 'DELETE_CACHE_URL',
                            url: props.loginUser.getIconUrl(),
                        });
                        }
                    });
                }
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    const loginUser = result.user;
                    const param = String(new Date().getTime());
                    loginUser.profileImageUrlParam = param;
                    afterSaveCommonProcess(loginUser);
                }
            }
            setProfImage(undefined);
            setPreviewImagePath("");
            setEditMode(MYPAGE_ITEM.PROFILE_PICTURE, false);
            setSaveProfImageProcessing(false);
            dispatch(ProcessAction({ processing: false, message: "" }));
        }
        /**
         * 性別保存
         */
        async function saveGender() {
            const updateUser = beforeSaveCommonProcess(MYPAGE_ITEM.GENDER);
            if (updateUser == null || saveGenderProcessing) {
                return;
            }
            setSaveGenderProcessing(true);
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    afterSaveCommonProcess(result.user);
                }
            }
            setEditMode(MYPAGE_ITEM.GENDER, false);
            setSaveGenderProcessing(false);
        }
        /**
         * 年代保存
         */
        async function saveAge() {
            const updateUser = beforeSaveCommonProcess(MYPAGE_ITEM.AGE);
            if (updateUser == null || saveAgeProcessing) {
                return;
            }
            setSaveAgeProcessing(true);
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    afterSaveCommonProcess(result.user);
                }
            }
            setEditMode(MYPAGE_ITEM.AGE, false);
            setSaveAgeProcessing(false);
        }
        /**
         * 属性保存
         */
        async function saveAttribute() {
            const updateUser = beforeSaveCommonProcess(MYPAGE_ITEM.ATTRIBUTE);
            if (updateUser == null || saveAttributeProcessing) {
                return;
            }
            setSaveAttributeProcessing(true);
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    afterSaveCommonProcess(result.user);
                }
            }
            setEditMode(MYPAGE_ITEM.ATTRIBUTE, false);
            setSaveAttributeProcessing(false);
        }
        /**
         * 地域保存
         */
        async function saveRegion() {
            const updateUser = beforeSaveCommonProcess(MYPAGE_ITEM.REGION);
            if (updateUser == null || saveRegionProcessing) {
                return;
            }
            setSaveRegionProcessing(true);
            const result = await UserRequest.save(updateUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd != null && result.rtnCd === 0) {
                if (result.user != null && result.bearerToken != null) {
                    result.user.bearerToken = result.bearerToken;
                    afterSaveCommonProcess(result.user);
                }
            }
            setEditMode(MYPAGE_ITEM.REGION, false);
            setSaveRegionProcessing(false);
        }
        /**
         * アカウント削除
         */
        async function deleteAccount() {
            if (email == null) {
                return;
            }
            if (isUnderProcess.current) {
                return;
            }
            try {
                isUnderProcess.current = true;
                setDialogMessage("");
                setOpenConfirmDialog(false);
                // ログアウト
                const result1 = await UserRequest.logout();
                if (result1 == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }
    
                if (result1.rtnCd == null || result1.rtnCd < 0) {
                    const message = intl.formatMessage({
                        id: "msg_delete_account_error",
                    });
                    setResultMessage(message);
                    setOpenResultDialog(true);
                }
                // アカウント削除
                const result2 = await UserRequest.deleteAccount(
                    props.loginUser,
                    email
                );
                if (result2 == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }
                if (result2.rtnCd == null || result2.rtnCd < 0) {
                    const message = intl.formatMessage({
                        id: "msg_delete_account_error",
                    });
                    setResultMessage(message);
                    setOpenResultDialog(true);
                } else {
                    AppServer.instance.disconnect();
                    dispatch(
                        UserAction({
                            loginUser: null,
                            lstOwnGroup: null,
                            lstBlockUser: null,
                            lstFollowUser: null,
                            lstTag: null,
                            lstCustomTag: null,
                            lstInvite: null,
                        })
                    );
                    dispatch(
                        UnreadCountAction({
                            mail: null,
                            talk: null,
                        })
                    );
                    dispatch(
                        EmitterAction({
                            instance: null,
                        })
                    );
                    window.location.href = "/";
                }
            } catch {}
        }

        /**
         * マイデータ取得
         */
        async function fetchMyData(): Promise<UserModel | null> {
            if (props.loginUser == null) {
                return null;
            }
            const result = await UserRequest.getMyData(props.loginUser);
            gotMyData.current = true;
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.user == null
            ) {
                return null;
            }
            if (result.email != null) {
                setEmail(result.email);
            }
            let user = result.user;
            user.bearerToken = props.loginUser.bearerToken;
            user.profileImageUrlParam = String(new Date().getTime());
            dispatch(
                UserAction({
                    loginUser: user,
                    lstOwnGroup: result.lstOwnGroup,
                    lstBlockUser: result.lstBlockUser,
                    lstFollowUser: result.lstFollow,
                    lstTag: result.lstTag,
                    lstCustomTag: result.lstCustomTag,
                    lstInvite: result.lstInvite,
                })
            );
            return user;
        }
        /**
         * タグリスト取得
         */
        async function fetchTagList() {
            let lstTag = getTagList(selector);
            let lstCustomTag = null;
            if (lstTag == null) {
                const result = await TagRequest.getList(props.loginUser);
                if (result != null) {
                    if (result.lstTag != null) {
                        lstTag = result.lstTag;
                        dispatch(
                            TagListAction({
                                list: lstTag,
                            })
                        );
                    }
                    if (result.lstCustomTag != null) {
                        lstCustomTag = result.lstCustomTag;
                        dispatch(
                            SelectedItemAction({
                                lstCustomTag: lstCustomTag,
                            })
                        );
                    }
                }
            }
            if (lstTag != null) {
                setTagList(lstTag);
            }
            if (lstCustomTag != null) {
                setSelectedCustomTagList(lstCustomTag);
            } else {
                setSelectedCustomTagList(undefined);
            }
        }

        /**
         * 国レコード取得
         * @param index
         * @returns
         */
        async function fetchCountryList(): Promise<CountryModel[] | null> {
            let result = await CountryRequest.getList();
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }

            if (result.rtnCd == null || result.rtnCd < 0) {
                return null;
            }
            let lstCountry = result.lstCountry;
            if (lstCountry == null) {
                return null;
            }
            dispatch(
                CountryListAction({
                    list: lstCountry,
                })
            );
            return lstCountry;
        }

        function setEditMode(item: MYPAGE_ITEM, value: boolean) {
            setEditModeUserName(false);
            setEditModeProfImage(false);
            setPreviewImagePath("");
            setProfImage(undefined);
            setEditModeGender(false);
            setEditModeAge(false);
            setEditModeAttribute(false);
            setEditModeRegion(false);
            switch (item) {
                case MYPAGE_ITEM.USERNAME:
                    setEditModeUserName(value);
                    break;
                case MYPAGE_ITEM.PROFILE_PICTURE:
                    setEditModeProfImage(value);
                    break;
                case MYPAGE_ITEM.GENDER:
                    setEditModeGender(value);
                    break;
                case MYPAGE_ITEM.AGE:
                    setEditModeAge(value);
                    break;
                case MYPAGE_ITEM.ATTRIBUTE:
                    setEditModeAttribute(value);
                    break;
                case MYPAGE_ITEM.REGION:
                    setEditModeRegion(value);
                    break;
                default:
                    break;
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper MyPage">
                {
                    // ページタイトル
                }
                <NavBar
                    showBack={false}
                    title={intl.formatMessage({ id: "title_mypage" })}
                />
                <div className="contents-wrapper">
                    {
                        // プロフィールページ
                    }
                    <Link
                        className={
                            DbConstants.getCssGenderLabel(
                                props.loginUser.gender
                            ) + " profile-link"
                        }
                        href="#"
                        onClick={goToProfPage}
                    >
                        <FormattedMessage id={"profile_page"} />
                    </Link>
                    <Divider className="divider" />
                    {
                        // ユーザ名
                    }
                    <div className="area username-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper username-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"user_name"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={editModeUserName ? "d-none" : "app-button "}
                                    variant="text"
                                    onClick={onClickEditUserName}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeUserName
                                            ? "app-button btn-cancel"
                                            : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditUserName}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={editModeUserName ? "app-button " : "d-none"}
                                    variant="text"
                                    onClick={onClickSaveEditUserName}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div>
                            <div
                                className={
                                    editModeUserName ? "d-none" : "value-label"
                                }
                            >
                                {props.loginUser.name != null
                                    ? props.loginUser.name
                                    : ""}
                            </div>
                            <OutlinedInput
                                className={
                                    editModeUserName
                                        ? "text-input-area"
                                        : "d-none"
                                }
                                fullWidth
                                inputProps={{
                                    maxLength: 40,
                                }}
                                value={userName}
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    {
                        // プロフィール画像
                    }
                    <div className="area profile-picture-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper profile-picture-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"profile_picture"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={
                                        editModeProfImage ? "d-none" : "app-button "
                                    }
                                    variant="text"
                                    onClick={onClickEditProfilePicture}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeProfImage
                                            ? "app-button btn-cancel"
                                            : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditProfilePicture}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={
                                        editModeProfImage ? "app-button" : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickSaveEditProfilePicture}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div>
                            <div
                                className={
                                    editModeProfImage ? "d-none" : "value-label"
                                }
                            >
                                {props.loginUser.getIconUrl() != null &&
                                    props.loginUser.getIconUrl().length > 0 && (
                                        <img
                                            src={props.loginUser.getIconUrl(props.loginUser.profileImageUrlParam)}
                                            alt="Profile Picture"
                                        />
                                    )}
                            </div>
                            <div
                                className={
                                    editModeProfImage
                                        ? "file-select-area"
                                        : "d-none"
                                }
                            >
                                <div
                                    {...getRootProps({
                                        className: "dropzone",
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <div className="image-area-wrapper">
                                        <p className="profile-picture-guide">
                                            Drag and drop some image files here,
                                            or click to select image files
                                        </p>
                                        {previewImagePath != null &&
                                            previewImagePath.length > 0 && (
                                                <img
                                                    src={previewImagePath}
                                                    alt="Profile Picture"
                                                />
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        // プロフィールメッセージ
                    }
                    <div className="area profile-text-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper profile-picture-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"title_profile_message"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    onClick={onClickEditProfile}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div>
                            <div
                                className="value-wrapper"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        profileHtml == null ? "" : profileHtml,
                                }}
                            />
                            <ProfileDialog
                                loginUser={props.loginUser}
                                openDialog={openDialogProfile}
                                onClose={onCloseProfileDialog}
                                onCancel={onCancelProfileDialog}
                                onOk={(_profileHtml, _profile) => {
                                    onOkProfileDialog(_profileHtml, _profile);
                                }}
                            />
                        </div>
                    </div>
                    {
                        // 性別
                    }
                    <div className="area gender-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper gender-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"sex"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={editModeGender ? "d-none" : "app-button"}
                                    variant="text"
                                    onClick={onClickEditGender}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeGender ? "app-button btn-cancel" : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditGender}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={editModeGender ? "app-button " : "d-none"}
                                    variant="text"
                                    onClick={onClickSaveEditGender}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box>
                            <div
                                className={
                                    editModeGender ? "d-none" : "value-label"
                                }
                            >
                                {props.loginUser.gender != null &&
                                    Utility.getGenderLabelKey(
                                        props.loginUser.gender
                                    ).length > 0 && (
                                        <FormattedMessage
                                            id={Utility.getGenderLabelKey(
                                                props.loginUser.gender
                                            )}
                                        />
                                    )}
                            </div>
                            <Select
                                className={
                                    editModeGender ? "select-box" : "d-none"
                                }
                                value={`${gender}`}
                                id="selectGender"
                                displayEmpty
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                fullWidth
                                onChange={(e: SelectChangeEvent) => {
                                    setGender(parseInt(e.target.value));
                                }}
                            >
                                <MenuItem value={`${DbConstants.GENDER_NONE}`}>
                                    {intl.formatMessage({
                                        id: "no_selection",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.GENDER_MALE}`}>
                                    {intl.formatMessage({
                                        id: Utility.getGenderLabelKey(
                                            DbConstants.GENDER_MALE
                                        ),
                                    })}
                                </MenuItem>
                                <MenuItem
                                    value={`${DbConstants.GENDER_FEMALE}`}
                                >
                                    {intl.formatMessage({
                                        id: Utility.getGenderLabelKey(
                                            DbConstants.GENDER_FEMALE
                                        ),
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.GENDER_OTHER}`}>
                                    {intl.formatMessage({
                                        id: Utility.getGenderLabelKey(
                                            DbConstants.GENDER_OTHER
                                        ),
                                    })}
                                </MenuItem>
                            </Select>
                        </Box>
                    </div>
                    {
                        // 年代
                    }
                    <div className="area age-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper age-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"age"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={editModeAge ? "d-none" : "app-button"}
                                    variant="text"
                                    onClick={onClickEditAge}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeAge ? "app-button btn-cancel" : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditAge}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={editModeAge ? "app-button" : "d-none"}
                                    variant="text"
                                    onClick={onClickSaveEditAge}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box>
                            <div
                                className={
                                    editModeAge ? "d-none" : "value-label"
                                }
                            >
                                {props.loginUser != null &&
                                    props.loginUser.age != null &&
                                    Utility.getAgeLabelKey(props.loginUser.age!)
                                        .length > 0 && (
                                        <FormattedMessage
                                            id={Utility.getAgeLabelKey(
                                                props.loginUser.age!
                                            )}
                                        />
                                    )}
                            </div>
                            <Select
                                className={
                                    editModeAge ? "select-box" : "d-none"
                                }
                                value={`${age}`}
                                id="selectAge"
                                displayEmpty
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                fullWidth
                                onChange={(e: SelectChangeEvent) => {
                                    setAge(parseInt(e.target.value));
                                }}
                            >
                                <MenuItem value={`${DbConstants.AGE_NONE}`}>
                                    {intl.formatMessage({
                                        id: "no_selection",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_10}`}>
                                    {intl.formatMessage({
                                        id: "age_10",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_20}`}>
                                    {intl.formatMessage({
                                        id: "age_20",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_30}`}>
                                    {intl.formatMessage({
                                        id: "age_30",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_40}`}>
                                    {intl.formatMessage({
                                        id: "age_40",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_50}`}>
                                    {intl.formatMessage({
                                        id: "age_50",
                                    })}
                                </MenuItem>
                                <MenuItem value={`${DbConstants.AGE_60}`}>
                                    {intl.formatMessage({
                                        id: "age_60",
                                    })}
                                </MenuItem>
                            </Select>
                        </Box>
                    </div>
                    {
                        // エリア
                    }
                    <div className="area region-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper region-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"region"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={editModeRegion ? "d-none" : "app-button"}
                                    variant="text"
                                    onClick={onClickEditRegion}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeRegion ? "app-button btn-cancel" : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditRegion}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={editModeRegion ? "app-button" : "d-none"}
                                    variant="text"
                                    onClick={onClickSaveEditRegion}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box>
                            <div
                                className={
                                    editModeRegion ? "d-none" : "value-label"
                                }
                            >
                                {props.loginUser != null &&
                                props.loginUser.region != null
                                    ? props.loginUser.region
                                    : ""}
                            </div>
                            <OutlinedInput
                                className={
                                    editModeRegion
                                        ? "text-input-area"
                                        : "d-none"
                                }
                                fullWidth
                                inputProps={{
                                    maxLength: 40,
                                }}
                                value={region}
                                onChange={(e) => {
                                    setRegion(e.target.value);
                                }}
                            />
                        </Box>
                    </div>
                    {
                        // 属性
                    }
                    <div className="area attribute-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper attribute-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"attribute"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className={
                                        editModeAttribute ? "d-none" : "app-button"
                                    }
                                    variant="text"
                                    onClick={onClickEditAttribute}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                                <Button
                                    className={
                                        editModeAttribute
                                            ? "app-button btn-cancel"
                                            : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickCancelEditAttribute}
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                                <Button
                                    className={
                                        editModeAttribute ? "app-button" : "d-none"
                                    }
                                    variant="text"
                                    onClick={onClickSaveEditAttribute}
                                >
                                    <FormattedMessage id={"btn_save"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        {props.loginUser.gender != null && (
                            <div>
                                <div
                                    className={
                                        editModeAttribute
                                            ? "d-none"
                                            : "value-label"
                                    }
                                >
                                    {props.loginUser != null &&
                                        props.loginUser.attribute != null &&
                                        Utility.getAttributeLabelKey(
                                            props.loginUser
                                        ).length > 0 && (
                                            <FormattedMessage
                                                id={Utility.getAttributeLabelKey(
                                                    props.loginUser
                                                )}
                                            />
                                        )}
                                </div>
                                <Select
                                    className={
                                        editModeAttribute
                                            ? "select-box"
                                            : "d-none"
                                    }
                                    value={`${attribute}`}
                                    id="selectAttribute"
                                    displayEmpty
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    fullWidth
                                    onChange={(e: SelectChangeEvent) => {
                                        setAttribute(parseInt(e.target.value));
                                    }}
                                >
                                    <MenuItem
                                        value={`${DbConstants.ATTRIBUTE_NONE}`}
                                    >
                                        {intl.formatMessage({
                                            id: "no_selection",
                                        })}
                                    </MenuItem>
                                    <MenuItem
                                        value={`${DbConstants.ATTRIBUTE_SADO}`}
                                    >
                                        {intl.formatMessage({
                                            id: Utility.getAttributeLabelKey2(
                                                props.loginUser.gender,
                                                DbConstants.ATTRIBUTE_SADO
                                            ),
                                        })}
                                    </MenuItem>
                                    <MenuItem
                                        value={`${DbConstants.ATTRIBUTE_MASO}`}
                                    >
                                        {intl.formatMessage({
                                            id: Utility.getAttributeLabelKey2(
                                                props.loginUser?.gender,
                                                DbConstants.ATTRIBUTE_MASO
                                            ),
                                        })}
                                    </MenuItem>
                                </Select>
                            </div>
                        )}
                    </div>
                    {
                        // 興味・関心
                    }
                    <div className="area interest-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper interest-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"interests"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    onClick={onClickEditTag}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div className="value-wrapper">
                            {lstSelectedCustomTag != null &&
                                lstSelectedCustomTag.map((customTag, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={
                                                DbConstants.getCssGenderLabel(
                                                    props.loginUser.gender
                                                ) + " tag-item"
                                            }
                                        >
                                            {customTag.name}
                                        </div>
                                    );
                                })}
                            {lstSelectedTag != null &&
                                lstSelectedTag.map((tag) => {
                                    return (
                                        <div
                                            key={tag.id}
                                            className={
                                                DbConstants.getCssGenderLabel(
                                                    props.loginUser.gender
                                                ) + " tag-item"
                                            }
                                        >
                                           {langCd === "ja" && (
                                                tag.name
                                            )}
                                            {langCd === "cn" && (
                                                tag.nameCn
                                            )}
                                            {langCd === "tw" && (
                                                tag.nameTw
                                            )}
                                            {langCd === "es" && (
                                                tag.nameEs
                                            )}
                                            {langCd === "de" && (
                                                tag.nameDe
                                            )}
                                            {langCd === "it" && (
                                                tag.nameIt
                                            )}
                                            {langCd === "ru" && (
                                                tag.nameRu
                                            )}
                                            {langCd !== "ja" &&
                                            langCd !== "cn" &&
                                            langCd !== "tw" &&
                                            langCd !== "es" &&
                                            langCd !== "de" &&
                                            langCd !== "it" &&
                                            langCd !== "ru" && (
                                                tag.nameEn
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        <SelectTagDialog
                            loginUser={props.loginUser}
                            openDialog={openDialogTag}
                            onClose={onCloseTagDialog}
                            onCancel={onCancelTagDialog}
                            onOk={onOkTagDialog}
                        />
                    </div>
                    {
                        // ギャラリー
                    }
                    <div className="area gallery-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper gallery-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"profile_contents"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    sx={{
                                        display: "block",
                                    }}
                                    onClick={onClickEditGallery}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box></Box>
                    </div>

                    {
                        // フォロー
                    }
                    <div className="area follow-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper follow-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"follow_user_list"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    sx={{
                                        display: "block",
                                    }}
                                    onClick={onClickManageFollow}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box></Box>
                    </div>
                    {
                        // フォロワー
                    }
                    <div className="area follower-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper follower-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"follower_list"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    sx={{
                                        display: "block",
                                    }}
                                    onClick={onClickManageFollower}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box></Box>
                    </div>
                    {
                        // ブロックユーザ
                    }
                    <div className="area block-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper block-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"block_list2"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    sx={{
                                        display: "block",
                                    }}
                                    onClick={onClickManageBlock}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box></Box>
                    </div>
                    {
                        // グループ
                    }
                    <div className="area group-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper group-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"group"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    sx={{
                                        display: "block",
                                    }}
                                    onClick={onClickManageGroup}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <Box></Box>
                    </div>
                    {
                        // 国
                    }
                    <div className="area country-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper country-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"country"} />
                            </div>
                            <div className="button-area">
                                <Button
                                    className="app-button"
                                    variant="text"
                                    onClick={onClickEditCountry}
                                >
                                    <FormattedMessage id={"btn_edit"} />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div>
                            <div className="value-wrapper">
                                {country != null && (
                                    <>
                                        <img
                                            src={`/images/flag/${country.code}.png`}
                                            alt="Country Flag"
                                        />
                                        <span>
                                            {langCd === "ja" && (
                                                country.nameJa
                                            )}
                                            {langCd === "cn" && (
                                                country.nameCn
                                            )}
                                            {langCd === "tw" && (
                                                country.nameTw
                                            )}
                                            {langCd === "es" && (
                                                country.nameEs
                                            )}
                                            {langCd === "de" && (
                                                country.nameDe
                                            )}
                                            {langCd === "it" && (
                                                country.nameIt
                                            )}
                                            {langCd === "ru" && (
                                                country.nameRu
                                            )}
                                            {langCd !== "ja" &&
                                            langCd !== "cn" &&
                                            langCd !== "tw" &&
                                            langCd !== "es" &&
                                            langCd !== "de" &&
                                            langCd !== "it" &&
                                            langCd !== "ru" && (
                                                country.nameEn
                                            )
                                        }
                                        </span>
                                    </>
                                )}
                            </div>
                            <SelectCountryDialog
                                loginUser={props.loginUser}
                                openDialog={openDialogCountry}
                                onClose={onCloseCountryDialog}
                                onCancel={onCancelCountryDialog}
                                onOk={onOkCountryDialog}
                            />
                        </div>
                    </div>
                    {
                        // アカウント
                    }
                    <div className="area account-area">
                        <div
                            className={
                                DbConstants.getCssGenderLabel(
                                    props.loginUser.gender
                                ) + " area-wrapper country-area-wrapper"
                            }
                        >
                            <div className="label-title">
                                <FormattedMessage id={"account"} />
                            </div>
                            <div className="button-area account">
                                {/* <Button
                                    className="app-button"
                                    variant="text"
                                    onClick={onClickDeleteAccount}
                                >
                                    <FormattedMessage
                                        id={"btn_account_delete"}
                                    />
                                </Button> */}

                                <Button
                                    color="error"
                                    variant="contained"
                                    onClick={onClickDeleteAccount}
                                >
                                    <FormattedMessage
                                        id={"btn_account_delete"}
                                    />
                                </Button>
                            </div>
                        </div>
                        <Divider className="divider" />
                        <div>
                            <div className="value-wrapper">{email}</div>
                        </div>
                    </div>

                    {
                        // ツイート
                    }
                    {
                        // アカウント
                    }
                    {
                        // ログアウト
                    }
                </div>
                <Dialog
                    className="dialog"
                    open={openConfirmDialog}
                    onClose={() => {
                        setOpenConfirmDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        className="dialog-title red"
                        id="alert-dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_delete_account"} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            dangerouslySetInnerHTML={{
                                __html: dialogMessage,
                            }}
                        ></DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button 
                            onClick={deleteAccount} 
                            color="error">
                            <FormattedMessage id={"btn_delete"} />
                        </Button>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenConfirmDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_cancel"} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="dialog"
                    open={openResultDialog}
                    onClose={() => {
                        setOpenResultDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        className="dialog-title red"
                        id="alert-dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_error"} />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{resultMessage}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenResultDialog(false);
                            }}
                            color="primary"
                        >
                            <FormattedMessage id={"btn_ok"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default MyPage;
