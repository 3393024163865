/***** import */
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
    Button,
    OutlinedInput,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    useScrollTrigger,
} from "@mui/material";
import { GroupRequest, JoinGroupRequest } from "api/requests/Requests";
import { UserModel, GroupModel, JoinGroupModel } from "models/Models";
import { NavBar, GroupMemberListItem } from "components/Components";
import { NetworkAction, ProcessAction, SelectedItemAction } from "redux/Actions";
import { getTimeDifference, getLanguageCode } from "redux/Selectors";
import Utility from "utils/Utility";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/group/GroupMemberList.scss";

/***** type */
type Props = {
    loginUser?: UserModel | null;
};

/**
 * グループメンバーリスト画面
 * @param props
 * @returns
 */
const GroupMemberList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupMemberList IN");
        /***** 定数 */
        const navigate = useNavigate();
        const intl = useIntl();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const container = React.useRef<HTMLDivElement>(null);
        const { groupId } = useParams<{ groupId: string }>();
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス
        const currentIndex = React.useRef<number>(0);
        // 読み込みサイズ
        const readSize = React.useRef<number>(100);

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // グループ
        const [group, setGroup] = React.useState<GroupModel>();
        // グループメンバーリスト
        const [lstMember, setMemberList] = React.useState<UserModel[]>();
        // 選択ユーザ
        const [selectedUser, setSelectedUser] = React.useState<UserModel>();
        // 追放メッセージ
        const [purgeMessage, setPurgeMessage] = React.useState<string>();
        const [openConfirmDialog, setOpenConfirmDialog] =
            React.useState<boolean>(false);
        // データロード完了フラグ
        const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
        // 確認ダイアログメッセージ
        const [dialogMessage, setDialogMessage] = React.useState<string>();
        // 処理結果メッセージ
        const [completeMessage, setCompleteMessage] =
            React.useState<string>("");
        const [errMessage, setErrorMessage] = React.useState<string>("");

        const trigger = useScrollTrigger({
            target: window,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループメンバー一覧",
                path: window.location.pathname,
            })
            window.scrollTo({ top: 0 });
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (props.loginUser != null) {
                (async () => {})();
            }
        }, [props.loginUser]);
        React.useEffect(() => {
            if (groupId != null) {
                (async () => {
                    const group = await getGroup();
                    if (group != null) {
                        setGroup(group);
                    }
                    dispatch(
                        ProcessAction({
                            processing: true,
                            message: "msg_loading",
                        })
                    );

                    // グループメンバーリスト取得
                    await fetchMemberList(group, currentIndex.current);

                    setDataLoaded(true);
                    dispatch(ProcessAction({ processing: false, message: "" }));
                })();
            }
        }, [groupId]);

        /**
         * ユーザクリック時
         * @param user
         */
        async function onClickUser(user: UserModel) {
            if (user != null) {
                const key = Utility.getUserKey(user);
                if (key.length > 0) {
                    dispatch(SelectedItemAction({ userId: user.id }));
                    window.setTimeout(function () {
                        navigate(`/users/${key}/profile`);
                    });
                }
            }
        }

        /**
         * 追放ボタン押下時
         * @param user
         */
        function onClickPurge(user: UserModel) {
            if (user.name == null || group == null || group.name == null) {
                return;
            }
            let message = intl.formatMessage({ id: "confirm_purge_member" });
            if (message != null) {
                message = message.replace("[USERNAME]", "[" + user.name + "]");
                message = message.replace(
                    "[GROUPNAME]",
                    "[" + group.name + "]"
                );
                setDialogMessage(message);
                setOpenConfirmDialog(true);
                setSelectedUser(user);
            }
        }

        /**
         * 追放
         * @returns
         */
        async function purge() {
            if (
                props.loginUser == null ||
                selectedUser == null ||
                group == null ||
                selectedUser.id == null ||
                group.id == null
            ) {
                return;
            }
            const result = await JoinGroupRequest.purge(
                props.loginUser,
                group.id,
                selectedUser.id,
                purgeMessage
            );
            setOpenConfirmDialog(false);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                setCompleteMessage("");
                const message = intl.formatMessage({ id: "err_common" });
                if (message != null) {
                    setErrorMessage(message);
                }
                return;
            }

            if (lstMember != null) {
                let idxDelete = -1;
                for (let i = 0; i < lstMember.length; i++) {
                    if (lstMember[i].id === selectedUser.id) {
                        idxDelete = i;
                        break;
                    }
                }
                if (idxDelete > -1) {
                    lstMember.splice(idxDelete, 1);
                    setMemberList([...lstMember]);
                    setErrorMessage("");
                    let message = intl.formatMessage({
                        id: "msg_purge_complete",
                    });
                    let userName = selectedUser.name;
                    if (message != null && userName != null) {
                        message = message.replace(
                            "[USERNAME]",
                            "[" + userName + "]"
                        );
                        setCompleteMessage(message);
                    }
                }
            }
        }

        /**
         * グループメンバーリスト取得
         * @param index
         * @returns
         */
        async function fetchMemberList(
            _group: GroupModel | null | undefined,
            index: number
        ) {
            if (_group == null || _group.id == null) {
                return;
            }
            const result = await GroupRequest.getGroupMemberList(
                _group.id,
                index
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            if (result.readSize != null) {
                readSize.current = result.readSize;
            }
            if (result.lstJoinGroup != null) {
                const wkMemberList: UserModel[] = [];
                result.lstJoinGroup.forEach((joinGroup: JoinGroupModel) => {
                    const member = joinGroup.user;
                    if (member != null) {
                        wkMemberList.push(member);
                    }
                });
                setMemberList(wkMemberList);
            }
        }

        /**
         * グループ情報取得
         */
        async function getGroup() {
            if (groupId != null) {
                const result = await GroupRequest.getDetail(Number(groupId));
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return;
                }
                if (
                    result.rtnCd != null &&
                    result.rtnCd === 0 &&
                    result.group != null
                ) {
                    return result.group;
                }
            }
        }

        /**
         * レンダリング
         */
        return (
            <div className="pageWrapper GroupMemberList">
                <NavBar
                    showBack={true}
                    title={intl.formatMessage({ id: "group_member_list" })}
                />
                {
                    // 処理結果表示エリア
                }
                {errMessage.length > 0 && (
                    <Alert severity="error">{errMessage}</Alert>
                )}
                {completeMessage.length > 0 && (
                    <Alert severity="success">{completeMessage}</Alert>
                )}
                {dataLoaded && lstMember != null && (
                    <>
                        <p className="group-name">
                            {group != null && group.name != null && group.name}
                        </p>
                        {langCd != null && timeDifference != null && (
                            <div className="list-container" ref={container}>
                                {lstMember.map((user: UserModel) => {
                                    return (
                                        <div
                                            className="list-item-wrapper"
                                            key={user.id}
                                            onClick={() => onClickUser(user)}
                                        >
                                            <GroupMemberListItem
                                                loginUser={props.loginUser}
                                                user={user}
                                                group={group}
                                                langCd={langCd}
                                                timeDifference={timeDifference}
                                                onClickPurge={() => {
                                                    onClickPurge(user);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="list-item-wrapper"></div>
                                <div className="list-item-wrapper"></div>
                                <div className="list-item-wrapper"></div>
                                <div className="list-item-wrapper"></div>
                                <div className="list-item-wrapper"></div>
                                <div className="list-item-wrapper"></div>
                            </div>
                        )}
                        {/* <Zoom in={trigger}>
                            <div className="scroll-to-top" role="presentation">
                                <Fab
                                    onClick={scrollToTop}
                                    color="primary"
                                    size="small"
                                    aria-label="scroll back to top"
                                >
                                    <KeyboardArrowUp />
                                </Fab>
                            </div>
                        </Zoom> */}
                        <Dialog
                            className="dialog"
                            open={openConfirmDialog}
                            onClose={() => {
                                setOpenConfirmDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                className="dialog-title"
                                id="alert-dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialogMessage}
                                </DialogContentText>
                                <div className="message-label">
                                    <FormattedMessage id="message" />
                                </div>
                                <OutlinedInput
                                    className="purge-message"
                                    fullWidth
                                    label=""
                                    type="text"
                                    multiline={true}
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    rows={5}
                                    value={purgeMessage}
                                    onChange={(e) => {
                                        setPurgeMessage(e.target.value);
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button 
                                    className="dialog-button"
                                    onClick={purge} 
                                    color="primary">
                                    <FormattedMessage id={"btn_yes2"} />
                                </Button>
                                <Button
                                    className="dialog-button"
                                    onClick={() => {
                                        setOpenConfirmDialog(false);
                                    }}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </div>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default GroupMemberList;
