import React from "react";
import { Box, Zoom, Fab, useScrollTrigger, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
    UserModel,
    ParentMailModel,
    HistorySentMailList,
    MailModel,
} from "models/Models";
import { KeyboardArrowUp } from "@mui/icons-material";
import { MailRequest } from "api/requests/Requests";
import {
    SentParentMailListItem,
    SentChildMailComponent,
    SendMessageDialog,
} from "components/Components";
import {
    ProcessAction,
    SelectedItemAction,
    HistoryAction,
    WorkingItemAction,
    NetworkAction,
} from "redux/Actions";
import {
    getSentMailListHistory,
    getTimeDifference,
    getLanguageCode,
} from "redux/Selectors";
import WriteIcon from "assets/images/write.svg";
import { pushDataLayer } from "gtm/gtm"

import "styles/pages/mail/SentParentMail.scss";

type Props = {
    loginUser: UserModel | null;
    selectedTab: number;
};

const SentParentMail: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ SentParentMail IN");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);
        const navigate = useNavigate();
        const theme = useTheme();
        const isSizeXS = useMediaQuery(theme.breakpoints.down("sm"));
        const langCd = getLanguageCode(selector);

        /***** useRef */
        const refTimeDifference = React.useRef<number>(
            getTimeDifference(selector)
        );
        const container = React.useRef<HTMLDivElement>(null);
        const refParentMailList = React.useRef<HTMLDivElement>();
        // 処理中フラグ
        const isUnderProcess = React.useRef<boolean>(false);
        // 読込中フラグ
        const nowFetching = React.useRef<boolean>(false);
        // タイマーID
        const timeoutId = React.useRef<number>(0);
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス日付
        const limitUnixTime = React.useRef<number>(0);
        const prevLimitUnixTime = React.useRef<number>(0);

        /***** useState */
        // 時差
        const [timeDifference, setTimeDifference] = React.useState<number>();
        // 親メールリスト読み込み完了フラグ
        const [loadedParentMail, setLoadedParentMail] =
            React.useState<boolean>(false);
        // 親メールリスト
        const [lstParentMail, setParentMailList] =
            React.useState<ParentMailModel[]>();
        // 選択親メール
        const [selectedParent, setSelectedParent] =
            React.useState<ParentMailModel>();
        // メッセージ送信ダイアログ表示フラグ
        const [openSendMessageDialog, setOpenSendMessageDialog] =
            React.useState<boolean>(false);

        // スクロールターゲット
        const [scrollTarget, setScrollTarget] = React.useState<
            Window | HTMLDivElement
        >();

        // const trigger = useScrollTrigger({
        //     target: scrollTarget,
        //     disableHysteresis: true,
        //     threshold: 300,
        // });

        const scrollToTop = React.useCallback(() => {
            if (refParentMailList.current != null) {
                refParentMailList.current.scrollTo({ top: 0 });
            }
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "送信メール一覧",
                path: window.location.pathname,
            });
            // clearMailData();
            (async () => {
                dispatch(
                    SelectedItemAction({
                        childMail: null,
                        parentMail: null,
                    })
                );
                dispatch(
                    ProcessAction({
                        processing: true,
                        message: "msg_loading",
                    })
                );
                const tmpParentMailList = await getParentMailList();
                if (tmpParentMailList != null && tmpParentMailList.length > 0) {
                    const parentMail = tmpParentMailList[0];
                    dispatch(SelectedItemAction({ parentMail: parentMail }));
                    setSelectedParent(parentMail);
                }
                dispatch(ProcessAction({ processing: false, message: "" }));
                window.setTimeout(() => {
                    setLoadedParentMail(true);
                });
            })();
            return () => {
                (async () => {
                    dispatch(SelectedItemAction({ parentMail: null }));
                })();
            };
        }, []);
        React.useEffect(() => {
        }, [props.selectedTab]);
        React.useEffect(() => {
            if (refTimeDifference.current != null) {
                setTimeDifference(refTimeDifference.current);
            }
        }, [refTimeDifference.current]);
        React.useEffect(() => {
            if (props.selectedTab !== 1) {
                return;
            }
            addScrollEventListener();
            return () => {
                removeScrollEventListener();
            };
        }, [lstParentMail]);

        function addScrollEventListener() {
            if (
                refParentMailList == null ||
                refParentMailList.current == null
            ) {
                window.setTimeout(addScrollEventListener, 100);
                return;
            }
            refParentMailList.current.addEventListener("scroll", onScroll);
        }
        function removeScrollEventListener() {
            if (
                refParentMailList != null &&
                refParentMailList.current != null
            ) {
                refParentMailList.current.removeEventListener(
                    "scroll",
                    onScroll
                );
            }
        }

        /**
         * スクロール位置取得
         * @returns
         */
        const getScrollPosition = (): number => {
            if (
                refParentMailList != null &&
                refParentMailList.current != null
            ) {
                return Math.max(refParentMailList.current.scrollTop);
            } else {
                return 0;
            }
        };

        /**
         * スクロール時
         * @param event
         * @returns
         */
        async function onScroll(event: any) {
            if (timeoutId.current !== 0) {
                return;
            }
            timeoutId.current = window.setTimeout(async function () {
                if (refParentMailList == null) {
                    return;
                }
                const el = refParentMailList.current;
                if (el == null) {
                    timeoutId.current = 0;
                    return;
                }
                const scrollHeight = el.scrollHeight;
                const scrollY = getScrollPosition();
                const windowHeight = window.innerHeight;
                if (scrollHeight - 50 < scrollY + windowHeight) {
                    if (!hasNext.current) {
                        return;
                    }
                    if (nowFetching.current) {
                        return;
                    }
                    nowFetching.current = true;

                    await getParentMailList();

                    nowFetching.current = false;
                }
                timeoutId.current = 0;
            }, 100);
        }

        /**
         * メッセージを書く
         */
        function onClickNewMessage() {
            dispatch(
                SelectedItemAction({
                    childMail: null,
                    parentMail: null,
                })
            );
            dispatch(
                WorkingItemAction({
                    mail: null,
                })
            );
            if (isSizeXS) {
                navigate("/mail/send");
            } else {
                setOpenSendMessageDialog(true);
            }
        }

        /**
         * 次レコード取得のためのLimitUnixTimeを取得
         * @returns
         */
        function getNextLimitUnixTime(limitTime: number) {
            let nextUnixTime = limitTime;
            if (nextUnixTime === 0) {
                nextUnixTime = Math.floor(new Date().getTime() / 1000);
            }
            // 6ヶ月前(60秒x60分x24時間x30日x6)のunixtime取得
            nextUnixTime = nextUnixTime - 15552000;
            return nextUnixTime;
        }
        /**
         * アイテムクリック時
         * @param parent_mail
         */
        async function onClickItem(parent_mail: ParentMailModel) {
            if (parent_mail.id != null) {
                if (isSizeXS) {
                    const scrollPos = getScrollPosition();
                    const history = getSentMailListHistory(
                        selector
                    ) as HistorySentMailList;
                    if (history != null) {
                        history.scrollPos = scrollPos;
                        dispatch(
                            HistoryAction({ sentMailListHistory: history })
                        );
                    }
                    dispatch(SelectedItemAction({ parentMail: parent_mail }));
                    window.setTimeout(function () {
                        navigate(`/mail/sent/${parent_mail.id}`);
                    });
                } else {
                    dispatch(SelectedItemAction({ parentMail: parent_mail }));
                    setSelectedParent(parent_mail);
                }
            }
        }

        /**
         * 新規メッセージダイアログ閉じる
         */
        async function onClose() {
            dispatch(SelectedItemAction({ childMail: null, lstUser: null }));
            setOpenSendMessageDialog(false);
        }
        /**
         * 新規メッセージ送信完了
         */
        async function onSendCompleted() {
            const scrollPos = getScrollPosition();
            const tmpParentMailList = await getParentMailList(true);
            if (selectedParent == null) {
                if (tmpParentMailList != null && tmpParentMailList.length > 0) {
                    const parentMail = tmpParentMailList[0];
                    dispatch(SelectedItemAction({ parentMail: parentMail }));
                    setSelectedParent(parentMail);
                }
            } else {
                window.setTimeout(() => {
                    if (
                        refParentMailList != null &&
                        refParentMailList.current != null
                    ) {
                        refParentMailList.current.scrollTo({
                            top: scrollPos,
                            behavior: "auto",
                        });
                    }
                }, 500);
            }

            setOpenSendMessageDialog(false);
            dispatch(SelectedItemAction({ childMail: null }));
            window.setTimeout(() => {
                getParentMailList(true);
            }, 500);
        }
        /**
         * 子メール削除
         * @returns
         */
        async function deleteMail(
            child_mail: MailModel,
            childMailCount: number
        ) {
            if (props.loginUser == null || props.loginUser.id == null) {
                return;
            }
            if (child_mail.receiverId == null || child_mail.senderId == null) {
                return;
            }
            if (selectedParent == null || selectedParent.id == null) {
                return;
            }
            if (lstParentMail == null) {
                return;
            }
            let parentId = selectedParent.id;
            for (let i = 0; i < lstParentMail.length; i++) {
                let parentMail = lstParentMail[i];
                if (parentMail.id === parentId) {
                    if (props.loginUser.id === parentMail.receiverId) {
                        if (props.loginUser.id === child_mail.senderId) {
                            if (
                                parentMail.receiverSendCnt != null &&
                                parentMail.receiverSendCnt > 0
                            ) {
                                parentMail.receiverSendCnt -= 1;
                            }
                            if (parentMail.receiverSendCnt === 0) {
                                parentMail.hidden = true;
                            }
                        }
                    } else if (props.loginUser.id === parentMail.senderId) {
                        if (props.loginUser.id === child_mail.senderId) {
                            if (
                                parentMail.senderSendCnt != null &&
                                parentMail.senderSendCnt > 0
                            ) {
                                parentMail.senderSendCnt -= 1;
                            }
                            if (parentMail.senderSendCnt === 0) {
                                parentMail.hidden = true;
                            }
                        }
                    }
                }
            }
            if (childMailCount === 0) {
                let index = -1;
                if (lstParentMail != null && selectedParent != null) {
                    for (let i = 0; i < lstParentMail.length; i++) {
                        const parentMail = lstParentMail[i];
                        if (parentMail.id === selectedParent.id) {
                            index = i;
                            break;
                        }
                    }
                }
                if (index > -1) {
                    if (lstParentMail.length > index + 1) {
                        for (let i = index + 1; i < lstParentMail.length; i++) {
                            const parentMail = lstParentMail[i];
                            if (!parentMail.hidden) {
                                dispatch(
                                    SelectedItemAction({
                                        parentMail: parentMail,
                                    })
                                );
                                setSelectedParent(parentMail);
                                break;
                            }
                        }
                    } else {
                        if (index - 1 >= 0) {
                            for (let i = index - 1; i >= 0; i--) {
                                const parentMail = lstParentMail[i];
                                if (!parentMail.hidden) {
                                    dispatch(
                                        SelectedItemAction({
                                            parentMail: parentMail,
                                        })
                                    );
                                    setSelectedParent(parentMail);
                                    break;
                                }
                            }
                        }
                    }
                }
                let newParentMailList = lstParentMail.filter((parentMail) => {
                    return !parentMail.hidden;
                });
                setParentMailList(newParentMailList);
            }
        }

        /**
         * 親メールリスト取得
         * @param index_receive
         * @param index_send
         * @returns
         */
        async function getParentMailList(
            force: boolean | null = null
        ): Promise<ParentMailModel[] | null> {
            if (!hasNext.current && force !== true) {
                return null;
            }
            if (force !== true) {
                if (limitUnixTime.current === 0) {
                    setParentMailList([]);
                }
            } else {
                limitUnixTime.current = 0;
                prevLimitUnixTime.current = 0;
                hasNext.current = true;
            }
            const limit = getNextLimitUnixTime(limitUnixTime.current);
            const prevLimit = limitUnixTime.current;
            const tmpParentMailList = await getParentMailListExecute(
                limit,
                prevLimit
            );
            return tmpParentMailList;
        }

        /**
         * 親メールリスト取得実行
         * @param index_receive
         * @param index_send
         * @returns
         */
        async function getParentMailListExecute(
            limit_unixtime: number,
            prev_limit_unixtime: number
        ): Promise<ParentMailModel[] | null> {
            if (props.loginUser == null || props.loginUser.id == null) {
                return null;
            }
            if (!hasNext.current) {
                return null;
            }
            if (isUnderProcess.current) {
                return null;
            }
            isUnderProcess.current = true;
            // リクエスト実行
            const result = await MailRequest.getSentParentMailList(
                props.loginUser,
                limit_unixtime,
                prev_limit_unixtime
            );
            try {
                if (result == null) {
                    if (window.navigator.onLine) {
                        navigate("/maintenance");
                    } else {
                        dispatch(NetworkAction({connected: false}));
                    }
                    return null;    
                }
                if (result.rtnCd == null || result.rtnCd < 0) {
                    throw new Error();
                }
                // hasNext
                if (result.hasNext != null) {
                    hasNext.current = result.hasNext;
                }
                // limitUnixTime
                if (result.limitUnixTime != null) {
                    limitUnixTime.current = result.limitUnixTime;
                }
                // prevLimitUnixTime
                if (result.prevLimitUnixTime != null) {
                    prevLimitUnixTime.current = result.prevLimitUnixTime;
                }
                // lstParentMailReceive
                let wkParentMailList: ParentMailModel[] = [];
                if (result.lstParentMailReceive != null) {
                    result.lstParentMailReceive.forEach((parentMail) => {
                        parentMail.sortDate = parentMail.updatedAtReceiverSend;
                        wkParentMailList.push(parentMail);
                    });
                }
                // lstParentMailSend
                if (result.lstParentMailSend != null) {
                    result.lstParentMailSend.forEach((parentMail) => {
                        parentMail.sortDate = parentMail.updatedAtSenderSend;
                        wkParentMailList.push(parentMail);
                    });
                }
                // ソート処理
                wkParentMailList.sort((a, b) => {
                    if (a.sortDate == null || b.sortDate == null) {
                        return 1;
                    } else {
                        const time1 = new Date(a.sortDate).getTime();
                        const time2 = new Date(b.sortDate).getTime();
                        return time1 > time2 ? -1 : 1;
                    }
                });
                wkParentMailList.forEach((parentMail) => {
                    if (props.loginUser != null && props.loginUser.id != null) {
                        if (props.loginUser.id === parentMail.receiverId) {
                            if (
                                parentMail.receiverUnreadCnt != null &&
                                parentMail.receiverUnreadCnt === 0
                            ) {
                                parentMail.read = true;
                            }
                        } else if (props.loginUser.id === parentMail.senderId) {
                            if (
                                parentMail.senderUnreadCnt != null &&
                                parentMail.senderUnreadCnt === 0
                            ) {
                                parentMail.read = true;
                            }
                        }
                    }
                });
                setParentMailList((prevParentMailList) => {
                    if (prev_limit_unixtime === 0) {
                        if (wkParentMailList == null) {
                            return prevParentMailList;
                        } else {
                            return wkParentMailList;
                        }
                    } else {
                        if (wkParentMailList == null) {
                            return prevParentMailList;
                        } else {
                            if (prevParentMailList == null) {
                                return wkParentMailList;
                            } else {
                                return prevParentMailList.concat(
                                    wkParentMailList
                                );
                            }
                        }
                    }
                });
                return wkParentMailList;
            } catch (e) {
                return null;
            } finally {
                isUnderProcess.current = false;
            }
        }

        function clearMailData() {
            refParentMailList.current = undefined;
            isUnderProcess.current = false;
            nowFetching.current = false;
            hasNext.current = true;
            limitUnixTime.current = 0;
            prevLimitUnixTime.current = 0;
            setLoadedParentMail(false);
            setParentMailList(undefined);
            setSelectedParent(undefined);
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper SentParentMail">
                {props.loginUser != null && loadedParentMail && (
                    <>
                        {lstParentMail != null && (
                            <div ref={container} className="contents-wrapper">
                                <div
                                    ref={(ref: HTMLDivElement) => {
                                        refParentMailList.current = ref;
                                        setScrollTarget(ref);
                                    }}
                                    className={
                                        lstParentMail.length === 0
                                            ? "no-record parent-list-wrapper"
                                            : "parent-list-wrapper"
                                    }
                                >
                                    {lstParentMail.length === 0 && (
                                        <div className="no-sent-page">
                                            <FormattedMessage
                                                id={
                                                    "msg_not_yet_exist_send_message"
                                                }
                                            />
                                        </div>
                                    )}
                                    {langCd != null &&
                                        timeDifference != null &&
                                        lstParentMail.length > 0 &&
                                        lstParentMail.map((parentMail) => {
                                            return (
                                                <React.Fragment
                                                    key={parentMail.id}
                                                >
                                                    {!parentMail.hidden && (
                                                        <div className="item-wrapper">
                                                            <SentParentMailListItem
                                                                parentMail={
                                                                    parentMail
                                                                }
                                                                selected={
                                                                    selectedParent !=
                                                                        null &&
                                                                    parentMail.id ===
                                                                        selectedParent.id
                                                                }
                                                                loginUser={
                                                                    props.loginUser as UserModel
                                                                }
                                                                langCd={langCd}
                                                                timeDifference={
                                                                    timeDifference
                                                                }
                                                                onClick={() => {
                                                                    onClickItem(
                                                                        parentMail
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                </div>
                                {lstParentMail.length > 0 && (
                                    <div className="child-list-wrapper">
                                        {selectedParent != null &&
                                            selectedParent.id != null && (
                                                <SentChildMailComponent
                                                    loginUser={props.loginUser}
                                                    parentId={String(
                                                        selectedParent.id
                                                    )}
                                                    onDelete={(
                                                        childMail: MailModel,
                                                        childMailCount
                                                    ) =>
                                                        deleteMail(
                                                            childMail,
                                                            childMailCount
                                                        )
                                                    }
                                                />
                                            )}
                                    </div>
                                )}

                                <SendMessageDialog
                                    open={openSendMessageDialog}
                                    close={onClose}
                                    onSendCompleted={onSendCompleted}
                                    loginUser={props.loginUser}
                                    parentMail={undefined}
                                />
                                <div className="icon-new-wrapper">
                                    <img
                                        className="new-message"
                                        src={WriteIcon}
                                        alt="Create Mail Button"
                                        onClick={onClickNewMessage}
                                    />
                                </div>
                                {/* <Zoom in={trigger}>
                                    <Box
                                        className="scroll-trigger"
                                        role="presentation"
                                    >
                                        <Fab
                                            onClick={scrollToTop}
                                            color="primary"
                                            size="small"
                                            aria-label="scroll back to top"
                                        >
                                            <KeyboardArrowUp />
                                        </Fab>
                                    </Box>
                                </Zoom> */}
                            </div>
                        )}
                    </>
                )}
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        if (prevProps.selectedTab !== nextProps.selectedTab) {
            return false;
        }
        return true;
    }
);

export default SentParentMail;
