/***** import */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
    SortableContainer,
    SortableElement,
    SortableContainerProps,
    SortableElementProps,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AppConstants, UrlConstants } from "constants/Constants";
import { NavBar } from "components/Components";
import { UserRequest, AlbumRequest } from "api/requests/Requests";
import {
    UserModel,
    AlbumModel,
    AlbumGroupModel,
    AlbumUserModel,
    AlbumTemplateModel,
} from "models/Models";
import {
    SelectedItemAction,
    AlbumTemplateAction,
    WorkingItemAction,
    NetworkAction,
} from "redux/Actions";
import { getAlbumTemplateList } from "redux/Selectors";
import Utility from "utils/Utility";
import AlbumCreate from "assets/images/album_create.svg";
import AlbumEdit from "assets/images/album_edit.svg";
import AlbumEditOff from "assets/images/album_edit_off.svg";
import AlbumManage from "assets/images/album_manage.svg";
import AlbumManageOff from "assets/images/album_manage_off.svg";
import Trash from "assets/images/trash.svg";
import TrashOff from "assets/images/trash_off.svg";
import { pushDataLayer } from "gtm/gtm"
import "styles/pages/mypage/AlbumList.scss";

/***** type */
type Props = {
    loginUser: UserModel;
};
class GroupAlbumInnerContainer {
    id: number = 0;
    album: AlbumModel | null = null;
    albumGroup: AlbumGroupModel | null = null;
}

class GroupAlbumListContainer {
    id: string = "";
    name: string = "";
    list: GroupAlbumInnerContainer[] = [];
}
class UserAlbumInnerContainer {
    id: number = 0;
    album: AlbumModel | null = null;
    albumUser: AlbumUserModel | null = null;
}
class UserAlbumListContainer {
    id: string = "";
    name: string = "";
    list: UserAlbumInnerContainer[] = [];
}

/**
 * アルバム未作成の場合
 */
const NoAlbumPage: React.FC<Props> = React.memo((props) => {
    /***** 定数 */
    const navigate = useNavigate();
    // 言語コード
    const langCd = Utility.getLanguageCode();
    // ユーザマーク
    const mark = Utility.getUserKey(props.loginUser);

    return (
        <Box className="no-album-page"
            sx={{
                height: {
                    xs: `calc(100% - ${AppConstants.NAVBAR_HEIGHT})`,
                }
            }}
        >
            <Box className="contents-wrapper"
                sx={{
                    height: "100%",
                }}
            >
                <Box
                    component="span"
                    sx={{
                        color: Utility.getGenderFontColor(
                            props.loginUser.gender
                        ),
                        fontSize: "1.5rem",
                        fontWeight: "bolder",
                        textAlign: "center",
                    }}
                >
                    {langCd === "ja" && (
                        <span>
                            <a
                                href={`/users/${mark}/profile`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                あなたのプロフィールサイト
                            </a>
                            に表示される画像や動画を追加して公開してみましょう
                        </span>
                    )}
                    {langCd !== "ja" && (
                        <span>
                            Add images and videos that will be displayed
                            on&nbsp;
                            <a
                                href={`/users/${mark}/profile`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                your profile site.
                            </a>
                        </span>
                    )}
                </Box>
                <div className="guide-sentenses">
                    <span className="text-start">
                        <FormattedMessage id={"album_guide_sentence1"} />
                    </span>
                    <span className="text-start">
                        <FormattedMessage id={"album_guide_sentence2"} />
                    </span>
                    <span className="text-start">
                        <FormattedMessage id={"album_guide_sentence3"} />
                    </span>
                </div>
            </Box>
            <Box className="btn-area">
                <Button
                    className="app-button"
                    variant="text"
                    sx={{
                        width: {
                            xs: "80%",
                            sm: "350px",
                        },
                        fontSize: "1.2rem",
                        margin: "auto",
                    }}
                    onClick={() => {
                        navigate(`/mypage/album/create`);
                    }}
                >
                    <FormattedMessage id={"btn_create_album"} />
                </Button>
            </Box>
        </Box>
    );
});

/**
 * アルバム(一般公開)一覧
 */
type SortableListPublicProps = {
    lstAlbum: AlbumModel[];
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const SortableListPublic = SortableContainer<
    SortableListPublicProps & SortableContainerProps
>((props: SortableListPublicProps) => {
    return (
        <ul className="album-list-contents-wrapper public">
            {props.lstAlbum.map((album, index) => {
                return (
                    <React.Fragment key={index}>
                        {album.albumTemplate != null &&
                            album.albumTemplate.name != null &&
                            album.name != null && (
                                <Album
                                    index={index}
                                    album={album}
                                    onClickAlbum={props.onClickAlbum}
                                    onDoubleClickAlbum={
                                        props.onDoubleClickAlbum
                                    }
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
});
type AlbumListPublicProps = {
    lstAlbum: AlbumModel[];
    onReorder: (lstAlbum: AlbumModel[]) => void;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const AlbumListPublic: React.FC<AlbumListPublicProps> = React.memo((props) => {
    const onSortEnd = ({
        oldIndex,
        newIndex,
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        const oldList = props.lstAlbum;
        const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
        props.onReorder(newList);
    };

    if (props.lstAlbum.length === 0) {
        return <></>;
    }
    return (
        <div className="album-list-area public">
            {
                // ヘッダー
            }
            <div className="album-list-header public">
                <h1>
                    <FormattedMessage id={"section_public"} />
                </h1>
            </div>
            <SortableListPublic
                axis="xy"
                lstAlbum={props.lstAlbum}
                onSortEnd={onSortEnd}
                onClickAlbum={props.onClickAlbum}
                onDoubleClickAlbum={props.onDoubleClickAlbum}
                distance={1}
            />
        </div>
    );
});
/**
 * アルバム(フォロワー公開)一覧
 */
type SortableListFollowerProps = {
    lstAlbum: AlbumModel[];
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const SortableListFollower = SortableContainer<
    SortableListFollowerProps & SortableContainerProps
>((props: SortableListFollowerProps) => {
    return (
        <ul className="album-list-contents-wrapper follower">
            {props.lstAlbum.map((album, index) => {
                return (
                    <React.Fragment key={index}>
                        {album.albumTemplate != null &&
                            album.albumTemplate.name != null &&
                            album.name != null && (
                                <Album
                                    index={index}
                                    album={album}
                                    onClickAlbum={props.onClickAlbum}
                                    onDoubleClickAlbum={
                                        props.onDoubleClickAlbum
                                    }
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
});
type AlbumListFollowerProps = {
    lstAlbum: AlbumModel[];
    onReorder: (lstAlbum: AlbumModel[]) => void;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const AlbumListFollower: React.FC<AlbumListFollowerProps> = React.memo(
    (props) => {
        const onSortEnd = ({
            oldIndex,
            newIndex,
        }: {
            oldIndex: number;
            newIndex: number;
        }) => {
            const oldList = props.lstAlbum;
            const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
            props.onReorder(newList);
        };

        if (props.lstAlbum.length === 0) {
            return <></>;
        }
        return (
            <div className="album-list-area follower">
                {/* ヘッダー */}
                <div className="album-list-header follower">
                    <h1>
                        <FormattedMessage id={"section_follower"} />
                    </h1>
                </div>
                {/* コンテンツ */}
                <SortableListFollower
                    axis="xy"
                    lstAlbum={props.lstAlbum}
                    onSortEnd={onSortEnd}
                    onClickAlbum={props.onClickAlbum}
                    onDoubleClickAlbum={props.onDoubleClickAlbum}
                    distance={1}
                />
            </div>
        );
    }
);
/**
 * アルバム(グループ公開)一覧
 */
type SortableListGroupProps = {
    list: GroupAlbumInnerContainer[];
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const SortableListGroup = SortableContainer<
    SortableListGroupProps & SortableContainerProps
>((props: SortableListGroupProps) => {
    return (
        <ul className="album-list-contents-wrapper group">
            {props.list.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {item.album != null &&
                            item.album.albumTemplate != null &&
                            item.album.albumTemplate.name != null &&
                            item.album.name != null && (
                                <Album
                                    index={index}
                                    album={item.album}
                                    onClickAlbum={props.onClickAlbum}
                                    onDoubleClickAlbum={
                                        props.onDoubleClickAlbum
                                    }
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
});
type AlbumListGroupProps = {
    lstGroupAlbum: GroupAlbumListContainer[];
    onReorder: (groupAlbum: GroupAlbumListContainer) => void;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const AlbumListGroup: React.FC<AlbumListGroupProps> = React.memo((props) => {
    const onSortEnd = (
        groupAlbum: GroupAlbumListContainer,
        oldIndex: number,
        newIndex: number
    ) => {
        const list = groupAlbum.list;
        if (list != null) {
            const oldList = list;
            const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
            groupAlbum.list = newList;
            props.onReorder(groupAlbum);
        }
    };

    if (props.lstGroupAlbum.length === 0) {
        return <></>;
    }

    return (
        <div className="album-list-area group">
            {props.lstGroupAlbum.map((groupAlbum) => {
                return (
                    <div
                        key={`group_${groupAlbum.id}`}
                        className="album-list-contents"
                    >
                        {/* ヘッダー */}
                        <div className="album-list-header group">
                            <h1>{groupAlbum.name}</h1>
                        </div>
                        {/* コンテンツ */}
                        <SortableListGroup
                            axis="xy"
                            list={groupAlbum.list}
                            onSortEnd={({
                                oldIndex,
                                newIndex,
                            }: {
                                oldIndex: number;
                                newIndex: number;
                            }) => {
                                onSortEnd(groupAlbum, oldIndex, newIndex);
                            }}
                            onClickAlbum={props.onClickAlbum}
                            onDoubleClickAlbum={props.onDoubleClickAlbum}
                            distance={1}
                        />
                    </div>
                );
            })}
        </div>
    );
});
/**
 * アルバム(特定ユーザ公開)一覧
 */
type SortableListUserProps = {
    list: UserAlbumInnerContainer[];
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const SortableListUser = SortableContainer<
    SortableListUserProps & SortableContainerProps
>((props: SortableListUserProps) => {
    return (
        <ul className="album-list-contents-wrapper user">
            {props.list.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {item.album != null &&
                            item.album.albumTemplate != null &&
                            item.album.albumTemplate.name != null &&
                            item.album.name != null && (
                                <Album
                                    index={index}
                                    album={item.album}
                                    onClickAlbum={props.onClickAlbum}
                                    onDoubleClickAlbum={
                                        props.onDoubleClickAlbum
                                    }
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
});
type AlbumListUserProps = {
    lstUserAlbum: UserAlbumListContainer[];
    onReorder: (userAlbum: UserAlbumListContainer) => void;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const AlbumListUser: React.FC<AlbumListUserProps> = React.memo((props) => {
    const onSortEnd = (
        userAlbum: UserAlbumListContainer,
        oldIndex: number,
        newIndex: number
    ) => {
        const list = userAlbum.list;
        if (list != null) {
            const oldList = list;
            const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
            userAlbum.list = newList;
            props.onReorder(userAlbum);
        }
    };

    if (props.lstUserAlbum.length === 0) {
        return <></>;
    }
    return (
        <div className="album-list-area user">
            {props.lstUserAlbum.map((userAlbum) => {
                return (
                    <div
                        key={`user_${userAlbum.id}`}
                        className="album-list-contents"
                    >
                        {/* ヘッダー */}
                        <div className="album-list-header user">
                            <h1>{userAlbum.name}</h1>
                        </div>
                        {/* コンテンツ */}
                        <SortableListUser
                            axis="xy"
                            list={userAlbum.list}
                            onSortEnd={({
                                oldIndex,
                                newIndex,
                            }: {
                                oldIndex: number;
                                newIndex: number;
                            }) => {
                                onSortEnd(userAlbum, oldIndex, newIndex);
                            }}
                            onClickAlbum={props.onClickAlbum}
                            onDoubleClickAlbum={props.onDoubleClickAlbum}
                            distance={1}
                        />
                    </div>
                );
            })}
        </div>
    );
});

/**
 * アルバム(非公開)一覧
 */
type SortableListPrivateProps = {
    lstAlbum: AlbumModel[];
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const SortableListPrivate = SortableContainer<
    SortableListPrivateProps & SortableContainerProps
>((props: SortableListPrivateProps) => {
    return (
        <ul className="album-list-contents-wrapper private">
            {props.lstAlbum.map((album, index) => {
                return (
                    <React.Fragment key={index}>
                        {album.albumTemplate != null &&
                            album.albumTemplate.name != null &&
                            album.name != null && (
                                <Album
                                    index={index}
                                    album={album}
                                    onClickAlbum={props.onClickAlbum}
                                    onDoubleClickAlbum={
                                        props.onDoubleClickAlbum
                                    }
                                />
                            )}
                    </React.Fragment>
                );
            })}
        </ul>
    );
});
type AlbumListPrivateProps = {
    lstAlbum: AlbumModel[];
    onReorder: (lstAlbum: AlbumModel[]) => void;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const AlbumListPrivate: React.FC<AlbumListPrivateProps> = React.memo(
    (props) => {
        const onSortEnd = ({
            oldIndex,
            newIndex,
        }: {
            oldIndex: number;
            newIndex: number;
        }) => {
            const oldList = props.lstAlbum;
            const newList = arrayMoveImmutable(oldList, oldIndex, newIndex);
            props.onReorder(newList);
        };

        if (props.lstAlbum.length === 0) {
            return <></>;
        }
        return (
            <div className="album-list-area private">
                {/* ヘッダー */}
                <div className="album-list-header private">
                    <h1>
                        <FormattedMessage id={"section_private"} />
                    </h1>
                </div>
                {/* コンテンツ */}
                <SortableListPrivate
                    axis="xy"
                    lstAlbum={props.lstAlbum}
                    onSortEnd={onSortEnd}
                    onClickAlbum={props.onClickAlbum}
                    onDoubleClickAlbum={props.onDoubleClickAlbum}
                    distance={1}
                />
            </div>
        );
    }
);

/**
 * アルバム
 */
type AlbumProps = {
    index: number;
    album: AlbumModel;
    onClickAlbum: (albumId: number) => void;
    onDoubleClickAlbum: (album: AlbumModel) => void;
};
const Album = SortableElement<AlbumProps & SortableElementProps>(
    (props: AlbumProps) => {
        const template = props.album.albumTemplate;
        if (template == null) {
            return <></>;
        }
        const albumName = props.album.name;
        const templateName = template.name;
        if (templateName == null || albumName == null) {
            return <></>;
        }

        function onClickAlbum() {
            if (props.album.id == null) {
                return;
            }
            props.onClickAlbum(props.album.id);
        }
        function onDoubleClickAlbum() {
            if (props.album.id == null) {
                return;
            }
            props.onDoubleClickAlbum(props.album);
        }
        return (
            <li
                className={
                    props.album.selected
                        ? "selected album-wrapper"
                        : "album-wrapper"
                }
                onClick={onClickAlbum}
                onDoubleClick={onDoubleClickAlbum}
            >
                <img
                    className="img-album-template"
                    src={UrlConstants.URL_S3_ALBUM_ICON + templateName}
                    alt="Album Template"
                />
                <span className="album-name">{albumName}</span>
            </li>
        );
    }
);

/**
 * アルバムリスト画面
 * @param props
 * @returns
 */
const AlbumList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ AlbumList IN");
        /***** 定数、変数 */
        const intl = useIntl();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const selector = useSelector((state) => state);

        /***** useState */
        // アルバムテンプレートリスト
        const [lstAlbumTemplate, setAlbumTemplateList] =
            React.useState<AlbumTemplateModel[]>();
        // 一般公開アルバムリスト
        const [lstAlbumPublic, setAlbumPublicList] =
            React.useState<AlbumModel[]>();
        // フォロワー公開アルバムリスト
        const [lstAlbumOpenFollower, setAlbumOpenFollowerList] =
            React.useState<AlbumModel[]>();
        // グループ公開アルバムリスト
        const [lstAlbumOpenGroup, setAlbumOpenGroupList] =
            React.useState<GroupAlbumListContainer[]>();
        // 特定ユーザ公開アルバムリスト
        const [lstAlbumOpenUser, setAlbumOpenUserList] =
            React.useState<UserAlbumListContainer[]>();
        // 非公開アルバムリスト
        const [lstAlbumPrivate, setAlbumPrivateList] =
            React.useState<AlbumModel[]>();
        // 選択中アルバム
        const [selectedAlbum, setSelectedAlbum] = React.useState<AlbumModel>();
        // アルバム存在フラグ
        const [albumExists, setAlbumExists] = React.useState<boolean>(false);
        // 初期データ読み込み完了フラグ
        const [initialDataLoaded, setInitialDataLoaded] =
            React.useState<boolean>(false);
        const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] =
            React.useState<boolean>(false);

        /**
         * useEffect
         */
        React.useEffect(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "アルバム一覧",
                path: window.location.pathname,
            });
        }, []);
        
        React.useEffect(() => {
            if (props.loginUser != null) {
                let lstAlbumTemplate = getAlbumTemplateList(selector);
                if (lstAlbumTemplate == null) {
                    (async () => {
                        lstAlbumTemplate = await fetchAlbumTemplateList();
                        if (lstAlbumTemplate != null) {
                            setAlbumTemplateList(lstAlbumTemplate);
                            dispatch(
                                AlbumTemplateAction({
                                    list: lstAlbumTemplate,
                                })
                            );
                            fetchUserAlbumList(lstAlbumTemplate);
                        }
                    })();
                } else {
                    setAlbumTemplateList(lstAlbumTemplate);
                    fetchUserAlbumList(lstAlbumTemplate);
                }
                dispatch(
                    SelectedItemAction({
                        lstGroup: null,
                        lstUser: null,
                    })
                );
                dispatch(
                    WorkingItemAction({
                        album: null,
                    })
                );
            }
        }, [props.loginUser]);

        /**
         * アルバムクリック時(一般公開)
         * @param albumId
         * @returns
         */
        function onClickAlbumPublic(albumId: number) {
            if (lstAlbumPublic == null) {
                return;
            }
            let deselect = false;
            if (selectedAlbum != null) {
                if (selectedAlbum.id === albumId) {
                    deselect = true;
                }
                deselectAlbum();
            }
            setAlbumPublicList((prevList) => {
                if (prevList == null) {
                    return prevList;
                }
                const newList = prevList.map((album: AlbumModel) => {
                    if (album.id === albumId) {
                        if (deselect) {
                            album.selected = false;
                            setSelectedAlbum(undefined);
                        } else {
                            album.selected = true;
                            setSelectedAlbum(album);
                        }
                    } else {
                        album.selected = false;
                    }
                    return album;
                });
                return newList;
            });
        }
        /**
         * アルバムクリック時(フォロワー公開)
         * @param albumId
         * @returns
         */
        function onClickAlbumFollower(albumId: number) {
            if (lstAlbumOpenFollower == null) {
                return;
            }
            let deselect = false;
            if (selectedAlbum != null) {
                if (selectedAlbum.id === albumId) {
                    deselect = true;
                }
                deselectAlbum();
            }
            setAlbumOpenFollowerList((prevList) => {
                if (prevList == null) {
                    return prevList;
                }
                const newList = prevList.map((album: AlbumModel) => {
                    if (album.id === albumId) {
                        if (deselect) {
                            album.selected = false;
                            setSelectedAlbum(undefined);
                        } else {
                            album.selected = true;
                            setSelectedAlbum(album);
                        }
                    } else {
                        album.selected = false;
                    }
                    return album;
                });
                return newList;
            });
        }
        /**
         * アルバムクリック時(グループ公開)
         * @param albumId
         * @returns
         */
        function onClickAlbumGroup(albumId: number) {
            if (lstAlbumOpenGroup == null) {
                return;
            }
            let deselect = false;
            if (selectedAlbum != null) {
                if (selectedAlbum.id === albumId) {
                    deselect = true;
                }
                deselectAlbum();
            }
            setAlbumOpenGroupList((prevList) => {
                if (prevList == null) {
                    return prevList;
                }
                const newList = prevList.map(
                    (listContainer: GroupAlbumListContainer) => {
                        const list = listContainer.list;
                        if (list != null) {
                            list.forEach(
                                (innerContainer: GroupAlbumInnerContainer) => {
                                    const album = innerContainer.album;
                                    if (album != null) {
                                        if (album.id === albumId) {
                                            if (deselect) {
                                                album.selected = false;
                                                setSelectedAlbum(undefined);
                                            } else {
                                                album.selected = true;
                                                setSelectedAlbum(album);
                                            }
                                        } else {
                                            album.selected = false;
                                        }
                                    }
                                }
                            );
                        }
                        return listContainer;
                    }
                );
                return newList;
            });
        }
        /**
         * アルバムクリック時(特定ユーザ公開)
         * @param albumId
         * @returns
         */
        function onClickAlbumUser(albumId: number) {
            if (lstAlbumOpenUser == null) {
                return;
            }
            let deselect = false;
            if (selectedAlbum != null) {
                if (selectedAlbum.id === albumId) {
                    deselect = true;
                }
                deselectAlbum();
            }
            setAlbumOpenUserList((prevList) => {
                if (prevList == null) {
                    return prevList;
                }
                const newList = prevList.map(
                    (listContainer: UserAlbumListContainer) => {
                        const list = listContainer.list;
                        if (list != null) {
                            list.forEach(
                                (innerContainer: UserAlbumInnerContainer) => {
                                    const album = innerContainer.album;
                                    if (album != null) {
                                        if (album.id === albumId) {
                                            if (deselect) {
                                                album.selected = false;
                                                setSelectedAlbum(undefined);
                                            } else {
                                                album.selected = true;
                                                setSelectedAlbum(album);
                                            }
                                        } else {
                                            album.selected = false;
                                        }
                                    }
                                }
                            );
                        }
                        return listContainer;
                    }
                );
                return newList;
            });
        }
        /**
         * アルバムクリック時(非公開)
         * @param albumId
         * @returns
         */
        function onClickAlbumPrivate(albumId: number) {
            if (lstAlbumPrivate == null) {
                return;
            }
            let deselect = false;
            if (selectedAlbum != null) {
                if (selectedAlbum.id === albumId) {
                    deselect = true;
                }
                deselectAlbum();
            }
            setAlbumPrivateList((prevList) => {
                if (prevList == null) {
                    return prevList;
                }
                const newList = prevList.map((album: AlbumModel) => {
                    if (album.id === albumId) {
                        if (deselect) {
                            album.selected = false;
                            setSelectedAlbum(undefined);
                        } else {
                            album.selected = true;
                            setSelectedAlbum(album);
                        }
                    } else {
                        album.selected = false;
                    }
                    return album;
                });
                return newList;
            });
        }

        /**
         * アルバム作成クリック時
         */
        function onClickAlbumCreate() {
            navigate(`/mypage/album/create`);
        }
        /**
         * アルバム編集クリック時
         * @returns
         */
        async function onClickAlbumEdit() {
            if (selectedAlbum == null || selectedAlbum.id == null) {
                return;
            }
            dispatch(SelectedItemAction({ album: selectedAlbum }));
            // window.setTimeout(() => {
            const albumId = selectedAlbum.id;
            navigate(`/mypage/album/${albumId}/setting`);
            // });
        }
        /**
         * ギャラリーリストクリック時
         * @returns
         */
        async function onClickGalleryList() {
            if (selectedAlbum == null || selectedAlbum.id == null) {
                return;
            }
            dispatch(SelectedItemAction({ album: selectedAlbum }));
            window.setTimeout(() => {
                const albumId = selectedAlbum.id;
                navigate(`/mypage/album/${albumId}/item_list`);
            });
        }
        /**
         * アルバム削除クリック時
         * @returns
         */
        function onClickTrash() {
            if (selectedAlbum == null || selectedAlbum.id == null) {
                return;
            }
            if (selectedAlbum.selected) {
                setOpenDeleteConfirmDialog(true);
            }
        }
        /**
         * アルバムダブルクリック時
         * @param album
         */
        async function onDoubleClickAlbum(album: AlbumModel) {
            setSelectedAlbum(album);
            dispatch(SelectedItemAction({ album: album }));
            window.setTimeout(() => {
                const albumId = album.id;
                navigate(`/mypage/album/${albumId}/item_list`);
            });
        }
        /**
         * 並び替え時(一般公開)
         * @param lstAlbum
         */
        function onReorderPublic(lstAlbum: AlbumModel[]) {
            setAlbumPublicList(lstAlbum);
            requestReorder(lstAlbum);
        }
        /**
         * 並び替え時(フォロワー公開)
         * @param lstAlbum
         */
        function onReorderFollower(lstAlbum: AlbumModel[]) {
            setAlbumPublicList(lstAlbum);
            requestReorder(lstAlbum);
        }
        /**
         * 並び替え時(グループ公開)
         * @param _groupAlbum
         */
        function onReorderGroup(_groupAlbum: GroupAlbumListContainer) {
            if (lstAlbumOpenGroup == null) {
                return;
            }
            const wkList = [...lstAlbumOpenGroup];
            let exist = false;
            let i = -1;
            for (i = 0; i < wkList.length; i++) {
                const groupAlbum = wkList[i];
                if (_groupAlbum.id === groupAlbum.id) {
                    exist = true;
                    break;
                }
            }
            if (exist) {
                wkList[i] = _groupAlbum;
            }
            setAlbumOpenGroupList(wkList);
            const list = _groupAlbum.list;
            if (list != null) {
                requestReorderGroup(list);
            }
        }
        /**
         * 並び替え時(特定ユーザ公開)
         * @param _userAlbum
         */
        function onReorderUser(_userAlbum: UserAlbumListContainer) {
            if (lstAlbumOpenUser == null) {
                return;
            }
            const wkList = [...lstAlbumOpenUser];
            let exist = false;
            let i = -1;
            for (i = 0; i < wkList.length; i++) {
                const userAlbum = wkList[i];
                if (_userAlbum.id === userAlbum.id) {
                    exist = true;
                    break;
                }
            }
            if (exist) {
                wkList[i] = _userAlbum;
            }
            setAlbumOpenUserList(wkList);
            const list = _userAlbum.list;
            if (list != null) {
                requestReorderUser(list);
            }
        }
        /**
         * 並び替え時(非公開)
         * @param lstAlbum
         */
        function onReorderPrivate(lstAlbum: AlbumModel[]) {
            setAlbumPrivateList(lstAlbum);
            requestReorder(lstAlbum);
        }

        /**
         * アルバム選択解除
         * @param albumId
         * @returns
         */
        function deselectAlbum() {
            if (selectedAlbum == null || selectedAlbum.id == null) {
                return;
            }
            const albumId = selectedAlbum.id;
            let deselected = false;
            if (lstAlbumPublic != null) {
                const newList = lstAlbumPublic.map((album: AlbumModel) => {
                    if (album.id === albumId) {
                        deselected = true;
                    }
                    album.selected = false;
                    return album;
                });
                if (deselected) {
                    setAlbumPublicList(newList);
                }
            }
            if (deselected) {
                return;
            }
            if (lstAlbumOpenFollower != null) {
                const newList = lstAlbumOpenFollower.map(
                    (album: AlbumModel) => {
                        if (album.id === albumId) {
                            deselected = true;
                        }
                        album.selected = false;
                        return album;
                    }
                );
                if (deselected) {
                    setAlbumOpenFollowerList(newList);
                }
            }
            if (deselected) {
                return;
            }
            if (lstAlbumOpenGroup != null) {
                const newList = lstAlbumOpenGroup.map(
                    (listContainer: GroupAlbumListContainer) => {
                        const list = listContainer.list;
                        if (list != null) {
                            list.forEach(
                                (innerContainer: GroupAlbumInnerContainer) => {
                                    const album = innerContainer.album;
                                    if (album != null) {
                                        if (album.id === albumId) {
                                            deselected = true;
                                        }
                                        album.selected = false;
                                    }
                                }
                            );
                        }
                        return listContainer;
                    }
                );
                if (deselected) {
                    setAlbumOpenGroupList(newList);
                }
            }
            if (deselected) {
                return;
            }
            if (lstAlbumOpenUser != null) {
                const newList = lstAlbumOpenUser.map(
                    (listContainer: UserAlbumListContainer) => {
                        const list = listContainer.list;
                        if (list != null) {
                            list.forEach(
                                (innerContainer: UserAlbumInnerContainer) => {
                                    const album = innerContainer.album;
                                    if (album != null) {
                                        if (album.id === albumId) {
                                            deselected = true;
                                        }
                                        album.selected = false;
                                    }
                                }
                            );
                        }
                        return listContainer;
                    }
                );
                if (deselected) {
                    setAlbumOpenUserList(newList);
                }
            }
            if (deselected) {
                return;
            }
            if (lstAlbumPrivate != null) {
                const newList = lstAlbumPrivate.map((album: AlbumModel) => {
                    if (album.id === albumId) {
                        deselected = true;
                    }
                    album.selected = false;
                    return album;
                });
                if (deselected) {
                    setAlbumPrivateList(newList);
                }
            }
        }

        /**
         * アルバム順序変更リクエスト
         * @param lstAlbum
         * @returns
         */
        async function requestReorder(lstAlbum: AlbumModel[]) {
            const lstAlbumId: string[] = [];
            const lstAlbumOrder: string[] = [];
            for (let i = 0; i < lstAlbum.length; i++) {
                const album = lstAlbum[i];
                if (album.id == null) {
                    return;
                }
                lstAlbumId.push(String(album.id));
                lstAlbumOrder.push(String(lstAlbum.length - i));
            }
            await AlbumRequest.reorder(
                props.loginUser,
                lstAlbumId,
                lstAlbumOrder
            );
        }
        /**
         * アルバム順序変更リクエスト(グループ)
         * @param lstAlbumGroup
         * @returns
         */
        async function requestReorderGroup(list: GroupAlbumInnerContainer[]) {
            const lstAlbumGroupId: string[] = [];
            const lstAlbumGroupOrder: string[] = [];
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (
                    item.id == null ||
                    item.album == null ||
                    item.albumGroup == null ||
                    item.albumGroup.id == null
                ) {
                    return;
                }
                lstAlbumGroupId.push(String(item.albumGroup.id));
                lstAlbumGroupOrder.push(String(list.length - i));
            }
            await AlbumRequest.reorderGroup(
                props.loginUser,
                lstAlbumGroupId,
                lstAlbumGroupOrder
            );
        }
        /**
         * アルバム順序変更リクエスト(ユーザー)
         * @param lstAlbumUser
         * @returns
         */
        async function requestReorderUser(list: UserAlbumInnerContainer[]) {
            const lstAlbumUserId: string[] = [];
            const lstAlbumUserOrder: string[] = [];
            for (let i = 0; i < list.length; i++) {
                const item = list[i];
                if (
                    item.id == null ||
                    item.album == null ||
                    item.albumUser == null ||
                    item.albumUser.id == null
                ) {
                    return;
                }
                lstAlbumUserId.push(String(item.albumUser.id));
                lstAlbumUserOrder.push(String(list.length - i));
            }
            await AlbumRequest.reorderUser(
                props.loginUser,
                lstAlbumUserId,
                lstAlbumUserOrder
            );
        }

        /**
         * アルバム削除
         * @returns
         */
        async function deleteAlbum() {
            if (selectedAlbum == null || selectedAlbum.id == null) {
                return;
            }
            const result = await AlbumRequest.deleteAlbum(
                props.loginUser,
                selectedAlbum.id
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (lstAlbumTemplate != null) {
                fetchUserAlbumList(lstAlbumTemplate);
            }
        }

        /**
         * ユーザアルバムリスト取得
         */
        async function fetchUserAlbumList(lstTemplate: AlbumTemplateModel[]) {
            if (props.loginUser == null) {
                return;
            }
            let result = await UserRequest.getUserAlbumList(props.loginUser);
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return;
            }
            if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (result.lstAlbum == null || result.lstAlbum.length === 0) {
                setAlbumExists(false);
            } else {
                setAlbumExists(true);
                assignAlbumByCategory(lstTemplate, result.lstAlbum);
            }
            setInitialDataLoaded(true);
        }
        /**
         * アルバムテンプレートリスト取得
         */
        async function fetchAlbumTemplateList(): Promise<
            AlbumTemplateModel[] | null
        > {
            if (props.loginUser == null) {
                return null;
            }
            let result = await AlbumRequest.getAlbumTemplateList();
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
            if (
                result.rtnCd == null ||
                result.rtnCd < 0 ||
                result.lstAlbumTemplate == null
            ) {
                return null;
            }
            return result.lstAlbumTemplate;
        }

        /**
         * アルバムをカテゴリーごとに振り分ける
         * @param lstAlbum
         */
        function assignAlbumByCategory(
            lstTemplate: AlbumTemplateModel[],
            lstAlbum: AlbumModel[]
        ) {
            const lstAlbumOpen: AlbumModel[] = [];
            const lstAlbumOpenFollower: AlbumModel[] = [];
            const lstAlbumOpenGroup: GroupAlbumListContainer[] = [];
            const lstAlbumOpenUser: UserAlbumListContainer[] = [];
            const lstAlbumPrivate: AlbumModel[] = [];

            for (let i = 0; i < lstAlbum.length; i++) {
                const album = lstAlbum[i];
                if (album.open === 1) {
                    lstAlbumOpen.push(album);
                } else if (album.openFollower === 1) {
                    lstAlbumOpenFollower.push(album);
                } else if (album.openGroup === 1) {
                    addAlbumToGroupAlbumListContainer(lstAlbumOpenGroup, album);
                } else if (album.openIndividual === 1) {
                    addAlbumToUserAlbumListContainer(lstAlbumOpenUser, album);
                } else {
                    lstAlbumPrivate.push(album);
                }
                for (let j = 0; j < lstTemplate.length; j++) {
                    const template = lstTemplate[j];
                    if (album.albumTemplateId === template.id) {
                        album.albumTemplate = template;
                        break;
                    }
                }
            }
            if (lstAlbumOpen.length > 0) {
                setAlbumPublicList(lstAlbumOpen);
            }
            if (lstAlbumOpenFollower.length > 0) {
                setAlbumOpenFollowerList(lstAlbumOpenFollower);
            }
            if (lstAlbumOpenGroup.length > 0) {
                for (let i = 0; i < lstAlbumOpenGroup.length; i++) {
                    const albumGroup = lstAlbumOpenGroup[i];
                    const list = albumGroup.list;
                    const newList = list.sort(function (a, b) {
                        if (
                            a.albumGroup == null ||
                            a.albumGroup.orderNo == null ||
                            b.albumGroup == null ||
                            b.albumGroup.orderNo == null
                        ) {
                            return 1;
                        } else {
                            return a.albumGroup.orderNo > b.albumGroup.orderNo
                                ? -1
                                : 1;
                        }
                    });
                    albumGroup.list = newList;
                }
                setAlbumOpenGroupList(lstAlbumOpenGroup);
            }
            if (lstAlbumOpenUser.length > 0) {
                for (let i = 0; i < lstAlbumOpenUser.length; i++) {
                    const albumUser = lstAlbumOpenUser[i];
                    const list = albumUser.list;
                    const newList = list.sort(function (a, b) {
                        if (
                            a.albumUser == null ||
                            a.albumUser.orderNo == null ||
                            b.albumUser == null ||
                            b.albumUser.orderNo == null
                        ) {
                            return 1;
                        } else {
                            return a.albumUser.orderNo > b.albumUser.orderNo
                                ? -1
                                : 1;
                        }
                    });
                    albumUser.list = newList;
                }
                setAlbumOpenUserList(lstAlbumOpenUser);
            }
            if (lstAlbumPrivate.length > 0) {
                setAlbumPrivateList(lstAlbumPrivate);
            }
        }

        /**
         * グループ公開アルバム追加
         * @param _lstAlbumOpenGroup
         * @param _album
         * @returns
         */
        function addAlbumToGroupAlbumListContainer(
            _lstAlbumOpenGroup: GroupAlbumListContainer[],
            _album: AlbumModel
        ): GroupAlbumListContainer[] {
            // 本アルバムを公開しているグループリスト
            const lstAlbumGroup = _album.lstAlbumGroup;
            if (lstAlbumGroup == null) {
                return _lstAlbumOpenGroup;
            }
            // 公開しているグループリストの数だけループ
            for (let i = 0; i < lstAlbumGroup.length; i++) {
                const albumGroup: AlbumGroupModel = lstAlbumGroup[i];
                if (albumGroup.id == null) {
                    continue;
                }
                const key = String(albumGroup.groupId);
                let exist = false;
                for (let j = 0; j < _lstAlbumOpenGroup.length; j++) {
                    const albumOpenGroup = _lstAlbumOpenGroup[j];
                    if (albumOpenGroup.id === key) {
                        exist = true;
                        const newInnerGroupAlbum: GroupAlbumInnerContainer =
                            new GroupAlbumInnerContainer();
                        newInnerGroupAlbum.album = _album;
                        newInnerGroupAlbum.albumGroup = albumGroup;
                        newInnerGroupAlbum.id = albumGroup.id;
                        albumOpenGroup.list.push(newInnerGroupAlbum);
                        break;
                    }
                }
                if (!exist) {
                    const group = albumGroup.group;
                    if (group != null && group.name != null) {
                        const newGroupAlbum: GroupAlbumListContainer =
                            new GroupAlbumListContainer();
                        newGroupAlbum.id = key;
                        newGroupAlbum.name = group.name;
                        const newInnerGroupAlbum: GroupAlbumInnerContainer =
                            new GroupAlbumInnerContainer();
                        newInnerGroupAlbum.album = _album;
                        newInnerGroupAlbum.albumGroup = albumGroup;
                        newInnerGroupAlbum.id = albumGroup.id;
                        newGroupAlbum.list = [newInnerGroupAlbum];
                        _lstAlbumOpenGroup.push(newGroupAlbum);
                    }
                }
            }
            return _lstAlbumOpenGroup;
        }
        /**
         * 特定ユーザ公開アルバム追加
         * @param _lstAlbumOpenUser
         * @param _album
         * @returns
         */
        function addAlbumToUserAlbumListContainer(
            _lstAlbumOpenUser: UserAlbumListContainer[],
            _album: AlbumModel
        ): UserAlbumListContainer[] {
            // 本アルバムを公開しているユーザリスト
            const lstAlbumUser = _album.lstAlbumUser;
            if (lstAlbumUser == null) {
                return _lstAlbumOpenUser;
            }
            // 公開しているユーザリストの数だけループ
            for (let i = 0; i < lstAlbumUser.length; i++) {
                const albumUser: AlbumUserModel = lstAlbumUser[i];
                if (albumUser.id == null) {
                    continue;
                }
                const key = String(albumUser.userId);
                let exist = false;
                for (let j = 0; j < _lstAlbumOpenUser.length; j++) {
                    const albumOpenUser = _lstAlbumOpenUser[j];
                    if (albumOpenUser.id === key) {
                        exist = true;
                        const newInnerUserAlbum: UserAlbumInnerContainer =
                            new UserAlbumInnerContainer();
                        newInnerUserAlbum.album = _album;
                        newInnerUserAlbum.albumUser = albumUser;
                        newInnerUserAlbum.id = albumUser.id;
                        albumOpenUser.list.push(newInnerUserAlbum);
                        break;
                    }
                }
                if (!exist) {
                    const user = albumUser.user;
                    if (user != null && user.name != null) {
                        const newUserAlbum: UserAlbumListContainer =
                            new UserAlbumListContainer();
                        newUserAlbum.id = key;
                        newUserAlbum.name = user.name;
                        const newInnerUserAlbum: UserAlbumInnerContainer =
                            new UserAlbumInnerContainer();
                        newInnerUserAlbum.album = _album;
                        newInnerUserAlbum.albumUser = albumUser;
                        newInnerUserAlbum.id = albumUser.id;
                        newUserAlbum.list = [newInnerUserAlbum];
                        _lstAlbumOpenUser.push(newUserAlbum);
                    }
                }
            }
            return _lstAlbumOpenUser;
        }
        /**
         * レンダリング
         */
        return (
            <>
                {initialDataLoaded && (
                    <div className="pageWrapper AlbumList">
                        {/* ページタイトル */}
                        <NavBar
                            showBack={true}
                            title={intl.formatMessage({
                                id: "title_profile_contents",
                            })}
                        />
                        {
                            // まだアルバム未作成の場合
                        }
                        {!albumExists && (
                            <NoAlbumPage loginUser={props.loginUser} />
                        )}
                        {
                            // 既にアルバムが存在する場合
                        }
                        {albumExists && (
                            <div className="album-list-area-wrapper">
                                {/* 一般公開アルバムリスト */}
                                {lstAlbumPublic != null &&
                                    lstAlbumPublic.length > 0 && (
                                        <AlbumListPublic
                                            lstAlbum={lstAlbumPublic}
                                            onReorder={(lstAlbum) => {
                                                onReorderPublic(lstAlbum);
                                            }}
                                            onClickAlbum={onClickAlbumPublic}
                                            onDoubleClickAlbum={(album) => {
                                                onDoubleClickAlbum(album);
                                            }}
                                        />
                                    )}
                                {/* フォロワー公開アルバムリスト */}
                                {lstAlbumOpenFollower != null &&
                                    lstAlbumOpenFollower.length > 0 && (
                                        <AlbumListFollower
                                            lstAlbum={lstAlbumOpenFollower}
                                            onReorder={(lstAlbum) => {
                                                onReorderFollower(lstAlbum);
                                            }}
                                            onClickAlbum={onClickAlbumFollower}
                                            onDoubleClickAlbum={(album) => {
                                                onDoubleClickAlbum(album);
                                            }}
                                        />
                                    )}
                                {/* グループ公開アルバムリスト */}
                                {lstAlbumOpenGroup != null &&
                                    lstAlbumOpenGroup.length > 0 && (
                                        <AlbumListGroup
                                            lstGroupAlbum={lstAlbumOpenGroup}
                                            onReorder={(groupAlbum) => {
                                                onReorderGroup(groupAlbum);
                                            }}
                                            onClickAlbum={onClickAlbumGroup}
                                            onDoubleClickAlbum={(album) => {
                                                onDoubleClickAlbum(album);
                                            }}
                                        />
                                    )}
                                {/* 特定ユーザ公開アルバムリスト */}
                                {lstAlbumOpenUser != null &&
                                    lstAlbumOpenUser.length > 0 && (
                                        <AlbumListUser
                                            lstUserAlbum={lstAlbumOpenUser}
                                            onReorder={(userAlbum) => {
                                                onReorderUser(userAlbum);
                                            }}
                                            onClickAlbum={onClickAlbumUser}
                                            onDoubleClickAlbum={(album) => {
                                                onDoubleClickAlbum(album);
                                            }}
                                        />
                                    )}
                                {/* 非公開アルバムリスト */}
                                {lstAlbumPrivate != null &&
                                    lstAlbumPrivate.length > 0 && (
                                        <AlbumListPrivate
                                            lstAlbum={lstAlbumPrivate}
                                            onReorder={(lstAlbum) => {
                                                onReorderPrivate(lstAlbum);
                                            }}
                                            onClickAlbum={onClickAlbumPrivate}
                                            onDoubleClickAlbum={(album) => {
                                                onDoubleClickAlbum(album);
                                            }}
                                        />
                                    )}
                                <div className="operation-area">
                                    {/* アルバム作成 */}
                                    <OverlayTrigger
                                        overlay={
                                            <Popover id="tooltip-disabled" body>
                                                <FormattedMessage
                                                    id={"tooltip_album_create"}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="operation-item album-create"
                                            src={AlbumCreate}
                                            alt="Create Album Button"
                                            onClick={onClickAlbumCreate}
                                        />
                                    </OverlayTrigger>
                                    {/* アルバム編集 */}
                                    <OverlayTrigger
                                        overlay={
                                            <Popover id="tooltip-disabled" body>
                                                <FormattedMessage
                                                    id={"tooltip_album_edit"}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="operation-item album-edit"
                                            src={
                                                selectedAlbum == null
                                                    ? AlbumEditOff
                                                    : AlbumEdit
                                            }
                                            alt="Edit Album Button"
                                            style={{
                                                cursor:
                                                    selectedAlbum == null
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            onClick={onClickAlbumEdit}
                                        />
                                    </OverlayTrigger>
                                    {/* アルバム管理 */}
                                    <OverlayTrigger
                                        overlay={
                                            <Popover id="tooltip-disabled" body>
                                                <FormattedMessage
                                                    id={"tooltip_album_manage"}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="operation-item album-manage"
                                            src={
                                                selectedAlbum == null
                                                    ? AlbumManageOff
                                                    : AlbumManage
                                            }
                                            alt="Manage Album Button"
                                            style={{
                                                cursor:
                                                    selectedAlbum == null
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            onClick={onClickGalleryList}
                                        />
                                    </OverlayTrigger>
                                    {/* アルバム削除 */}
                                    <OverlayTrigger
                                        overlay={
                                            <Popover id="tooltip-disabled" body>
                                                <FormattedMessage
                                                    id={"tooltip_album_delete"}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="operation-item album-delete"
                                            src={
                                                selectedAlbum == null
                                                    ? TrashOff
                                                    : Trash
                                            }
                                            alt="Delete Album Button"
                                            style={{
                                                cursor:
                                                    selectedAlbum == null
                                                        ? "not-allowed"
                                                        : "pointer",
                                            }}
                                            onClick={onClickTrash}
                                        />
                                    </OverlayTrigger>
                                </div>
                            </div>
                        )}
                        <Dialog
                            className="dialog"
                            open={openDeleteConfirmDialog}
                            onClose={() => {
                                setOpenDeleteConfirmDialog(false);
                            }}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle
                                id="alert-dialog-title"
                                className="dialog-title"
                            >
                                <FormattedMessage id={"dlg_title_message"} />
                            </DialogTitle>
                            <DialogContent
                                sx={{
                                    padding: {
                                        xs: "5px",
                                        sm: "10px",
                                    },
                                    paddingTop: {
                                        xs: "5px !important",
                                        sm: "10px !important",
                                    },
                                }}
                            >
                                <DialogContentText id="alert-dialog-description">
                                    <FormattedMessage
                                        id={"confirm_delete_album"}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    className="dialog-button"
                                    onClick={async () => {
                                        await deleteAlbum();
                                        setOpenDeleteConfirmDialog(false);
                                    }}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_yes2"} />
                                </Button>
                                <Button
                                    className="dialog-button"
                                    onClick={() => {
                                        setOpenDeleteConfirmDialog(false);
                                    }}
                                    color="primary"
                                >
                                    <FormattedMessage id={"btn_cancel"} />
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);

export default AlbumList;
