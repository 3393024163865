import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
    Box,
    TextField,
    Button,
    Zoom,
    Fab,
    useScrollTrigger,
    IconButton,
} from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";
import { useSelector } from "react-redux";
import {
    getLanguageCode,
    getTimeDifference,
    getSelectedUserList,
} from "redux/Selectors";
import { SearchUserCondition, UserModel } from "models/Models";
import { UserListItem } from "components/Components";
import { UserRequest } from "api/requests/Requests";
import Search from "@mui/icons-material/Search";
import "styles/components/SelectUserComponent.scss";

interface Props {
    loginUser: UserModel | undefined;
    onCancel: () => void;
    onOk: (lstSelectedUser: UserModel[] | null) => void;
}
export const SelectUserComponent: React.FC<Props> = (props) => {
    // Utility.log("===== SelectUserComponent");
    /***** 定数、変数 */
    const intl = useIntl();
    const selector = useSelector((state) => state);
    const langCd = getLanguageCode(selector);

    /***** useRef */
    const refTimeDifference = React.useRef<number>(getTimeDifference(selector));
    const container = React.useRef<HTMLDivElement>();
    // 処理中フラグ
    const isUnderProcess = React.useRef<boolean>(false);
    // 読込中フラグ
    const nowFetching = React.useRef(false);
    // タイマーID
    const timeoutId = React.useRef(0);
    // 次レコード有無
    const hasNext = React.useRef<boolean>(true);
    // 読み込みインデックス
    const currentIndex = React.useRef<number>(0);
    // 読み込みサイズ
    const readSize = React.useRef<number>(100);
    // 検索結果０件表示フラグ
    const [showNoRecord, setShowNoRecord] = React.useState<boolean>(false);
    // 選択ユーザリスト参照
    const refSelectedUserList = React.useRef<UserModel[]>();
    // クリックカウント
    const  refClickCount = React.useRef<number>(0);

    /***** useState */
    // 時差
    const [timeDifference, setTimeDifference] = React.useState<number>();
    // ユーザリスト
    const [lstUser, setUserList] = React.useState<UserModel[]>();
    // 選択ユーザリスト
    const [lstSelectedUser, setSelectedUserList] =
        React.useState<UserModel[]>();
    // 検索ユーザ名
    const [userName, setUserName] = React.useState<string>("");

    const trigger = useScrollTrigger({
        target: container.current,
        disableHysteresis: true,
        threshold: 300,
    });

    const scrollToTop = React.useCallback(() => {
        if (container.current != null) {
            container.current.scrollTo({ top: 0 });
        }
    }, []);

    /**
     * useEffect
     */
    React.useEffect(() => {
        let lstSelectedUser = getSelectedUserList(selector);
        if (lstSelectedUser != null) {
            lstSelectedUser = lstSelectedUser.map((group: UserModel) => {
                group.selected = true;
                return group;
            });
            setSelectedUserList(lstSelectedUser);
            refSelectedUserList.current = lstSelectedUser;
            setUserList([...lstSelectedUser]);
        }
    }, []);
    React.useEffect(() => {
        if (refTimeDifference.current != null) {
            setTimeDifference(refTimeDifference.current);
        }
    }, [refTimeDifference.current]);
    React.useEffect(() => {
        if (container != null && container.current != null) {
            container.current.addEventListener("scroll", onScroll);
        }
        return () => {
            if (container != null && container.current != null) {
                container.current.removeEventListener("scroll", onScroll);
            }
        };
    }, [lstUser]);

    /**
     * スクロール位置取得
     * @returns
     */
    const getScrollPosition = (): number => {
        if (container != null && container.current != null) {
            return Math.max(container.current.scrollTop);
        } else {
            return 0;
        }
    };

    /**
     * スクロール時
     * @param event
     * @returns
     */
    async function onScroll(event: any) {
        if (timeoutId.current !== 0) {
            return;
        }
        timeoutId.current = window.setTimeout(async function () {
            const el = container.current;
            if (el == null) {
                timeoutId.current = 0;
                return;
            }
            const scrollHeight = el.scrollHeight;
            const scrollY = getScrollPosition();
            const windowHeight = window.innerHeight;
            if (scrollHeight - 50 < scrollY + windowHeight) {
                if (!hasNext.current) {
                    return;
                }
                if (nowFetching.current) {
                    return;
                }
                nowFetching.current = true;

                currentIndex.current = currentIndex.current + readSize.current;
                await executeSearch(currentIndex.current);

                nowFetching.current = false;
            }
            timeoutId.current = 0;
        }, 100);
    }
    /**
     * ユーザ選択時、選択解除時
     * @param user
     */
    function onClickUser(user: UserModel) {
        if (user.id == null) {
            return;
        }
        refClickCount.current++;
        if (refClickCount.current > 1) {
            window.setTimeout(() => {
                user.selected = true;
                onDoubleClickUser(user);
                refClickCount.current = 0;
            });
            return;
        }
        if (refClickCount.current < 2) {
            setSelectedUserList((prevList) => {
                if (prevList == null) {
                    // 選択 => 選択解除
                    if (user.selected) {
                        return prevList;
                    }
                    // 非選択 => 選択
                    else {
                        user.selected = true;
                        return [user];
                    }
                } else {
                    let exist = false;
                    const newList = prevList.map((selectedUser: UserModel) => {
                        if (user.id === selectedUser.id) {
                            exist = true;
                            // 選択 => 選択解除
                            if (user.selected) {
                                selectedUser.selected = false;
                            }
                            // 非選択 => 選択
                            else {
                                selectedUser.selected = true;
                            }
                        }
                        return selectedUser;
                    });
                    if (!exist && !user.selected) {
                        user.selected = true;
                        newList.push(user);
                    }
                    refSelectedUserList.current = newList;
                    return newList;
                }
            });
            reflectSelectedStatus();
        }

        window.setTimeout(() => {
            refClickCount.current = 0;
        }, 300);
    }
    function onDoubleClickUser(_user: UserModel) {
        if (_user.id == null) {
            return;
        }
        let list = lstSelectedUser;
        if (list == null) {
            list = [_user];
        } else {
            let exist = false;
            for (let i=0; i<list.length; i++) {
                const user = list[i];
                if (user.id === _user.id) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                list.push(_user);
            }
        }
        reflectSelectedStatus();
        onClickOk();
    }
    /**
     * OK時
     */
    async function onClickOk() {
        let lstSelectedUserNew: UserModel[] | null = null;
        if (lstSelectedUser != null) {
            lstSelectedUserNew = lstSelectedUser.filter((user) => {
                return user.selected;
            });
        }
        // dispatch(SelectedItemAction({ lstUser: lstSelectedUserCommitted }));
        // window.setTimeout(() => {
        //     props.onOk();
        // });
        props.onOk(lstSelectedUserNew);
    }
    /**
     * キャンセル時
     */
    function onClickCancel() {
        props.onCancel();
    }
    /**
     * 選択ステータス反映
     */
    function reflectSelectedStatus() {
        if (lstUser == null) {
            return;
        }
        if (
            refSelectedUserList == null ||
            refSelectedUserList.current == null
        ) {
            return;
        }
        const wkUserList = lstUser.map((group) => {
            let selected = false;
            if (
                refSelectedUserList != null &&
                refSelectedUserList.current != null
            ) {
                for (let i = 0; i < refSelectedUserList.current.length; i++) {
                    const selectedUser = refSelectedUserList.current[i];
                    if (group.id === selectedUser.id && selectedUser.selected) {
                        selected = true;
                        break;
                    }
                }
            }
            group.selected = selected;
            return group;
        });
        if (wkUserList != null) {
            setUserList(wkUserList);
        }
    }

    /**
     * 検索ボタン押下時
     */
    async function onClickSearch() {
        currentIndex.current = 0;
        executeSearch(currentIndex.current);
    }
    /**
     * ユーザ検索実行
     * @param index
     * @returns
     */
    async function executeSearch(index: number) {
        let condition = new SearchUserCondition();
        if (props.loginUser != null && props.loginUser.id != null) {
            condition.userId = props.loginUser.id;
        }
        const wkUserName = userName.trim();
        setUserName(wkUserName);
        if (wkUserName == null || wkUserName.length === 0) {
            return;
        }
        condition.index = index;
        if (userName == null) {
            condition.name = "";
        } else {
            condition.name = wkUserName;
        }
        if (isUnderProcess.current) {
            return;
        }
        isUnderProcess.current = true;
        if (index === 0) {
            setUserList([]);
        }
        // 検索実行
        try {
            const result = await UserRequest.search(condition);
            if (result == null) {
                setShowNoRecord(true);
                return;
            }
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            if (result.readSize != null) {
                readSize.current = result.readSize;
            }
            let wkUserList: UserModel[] = [];
            if (index === 0) {
                if (lstSelectedUser != null) {
                    wkUserList = [...lstSelectedUser];
                }
            }
            if (result.users != null) {
                setShowNoRecord(false);
                for (let i = 0; i < result.users.length; i++) {
                    const user = result.users[i];
                    if (user.id != null) {
                        if (lstSelectedUser != null) {
                            let exist = false;
                            for (let j = 0; j < lstSelectedUser.length; j++) {
                                if (user.id === lstSelectedUser[j].id) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (exist) {
                                continue;
                            }
                        }
                        wkUserList.push(user);
                    }
                }
                setUserList((prevUserList) => {
                    if (index === 0) {
                        return wkUserList;
                    } else {
                        if (prevUserList != null) {
                            return prevUserList.concat(wkUserList);
                        } else {
                            return wkUserList;
                        }
                    }
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            isUnderProcess.current = false;
        }
    }

    return (
        <div className="component SelectUserComponent">
            <div className="search-area">
                <div className="condition-area">
                    {/* 検索ユーザ名 */}
                    <TextField
                        className="txt-user-name"
                        fullWidth
                        placeholder={intl.formatMessage({
                            id: "user_name",
                        })}
                        type="text"
                        value={userName}
                        onChange={(event) => setUserName(event.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onClickSearch();
                            }
                        }}
                    />
                    <IconButton 
                        className="btn-search" 
                        onClick={onClickSearch}>
                        <Search />
                    </IconButton>
                </div>
                {/* ユーザリスト */}
                <Box ref={container} className="result-area">
                    {/* 検索結果０件の場合 */}
                    {showNoRecord && (
                        <div className="no-record">
                            <FormattedMessage id={"msg_no_data"} />
                        </div>
                    )}
                    {!showNoRecord &&
                        langCd != null &&
                        timeDifference != null &&
                        lstUser != null &&
                        lstUser.map((user: UserModel, index: number) => {
                            return (
                                <div
                                    key={index}
                                    className="item-wrapper"
                                    onClick={() => {
                                        onClickUser(user);
                                    }}
                                >
                                    <UserListItem
                                        key={index}
                                        user={user}
                                        langCd={langCd}
                                        profImageUrlParam={user.id === props.loginUser?.id ? props.loginUser.profileImageUrlParam : null}
                                        timeDifference={timeDifference}
                                    />
                                </div>
                            );
                        })}
                    {/* {trigger != null && (
                        <Zoom in={trigger}>
                            <Box
                                className="scroll-trigger"
                                role="presentation"
                                sx={{
                                    position: "fixed",
                                    bottom: {
                                        xs: 100,
                                        sm: 32,
                                    },
                                    right: 10,
                                    zIndex: 100,
                                }}
                            >
                                <Fab
                                    onClick={scrollToTop}
                                    color="primary"
                                    size="small"
                                    aria-label="scroll back to top"
                                >
                                    <KeyboardArrowUp />
                                </Fab>
                            </Box>
                        </Zoom>
                    )} */}
                </Box>
            </div>
            {
                // ボタンエリア
            }
            <div className="button-area">
                <Button
                    className="app-button button btn-cancel"
                    variant="text"
                    onClick={onClickCancel}
                >
                    <FormattedMessage id={"btn_cancel"} />
                </Button>
                <Button
                    className="app-button button btn-ok"
                    variant="text"
                    onClick={onClickOk}
                >
                    <FormattedMessage id={"btn_ok"} />
                </Button>
            </div>
        </div>
    );
};
