import React from "react";
import { FormattedMessage } from "react-intl";
import {
    Box,
    Zoom,
    Fab,
    useScrollTrigger,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";

import { GroupRequest } from "api/requests/Requests";
import { UserModel, GroupModel, JoinGroupModel } from "models/Models";
import { useDispatch } from "react-redux";
import { GroupListItem } from "components/Components";
import { NetworkAction, SelectedItemAction } from "redux/Actions";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowUp } from "@mui/icons-material";
import AddIcon from "assets/images/add.svg";
import { pushDataLayer } from "gtm/gtm"

import "styles/pages/group/GroupList.scss";

type Props = {
    loginUser: UserModel | null;
};

const GroupList: React.FC<Props> = React.memo(
    (props) => {
        // Utility.log("@@@@@ GroupList");
        /***** 定数、変数 */
        const dispatch = useDispatch();
        const navigate = useNavigate();
        let countryCd = "99";

        /***** useRef */
        const refGroupList = React.useRef<HTMLDivElement>();
        // 次レコード有無
        const hasNext = React.useRef<boolean>(true);
        // 読み込みインデックス
        const currentIndex = React.useRef<number>(0);
        // 読込中フラグ
        const nowFetching = React.useRef<boolean>(false);
        // タイマーID
        const timeoutId = React.useRef<number>(0);
        // 読み込みサイズ
        const readSize = React.useRef<number>(100);
        // 加入中(被招待中、申請中含む)グループリスト
        const lstJoinGroup = React.useRef<JoinGroupModel[]>([]);

        /***** useState */
        // グループリスト
        const [lstGroup, setGroupList] = React.useState<GroupModel[]>([]);
        // 初期データロード完了フラグ
        const [initialDataLoaded, setInitialDataLoaded] =
            React.useState<boolean>(false);
        // ダイアログ表示フラグ
        const [openDialog, setOpenDialog] = React.useState<boolean>(false);
        const trigger = useScrollTrigger({
            target: refGroupList.current,
            disableHysteresis: true,
            threshold: 300,
        });

        const scrollToTop = React.useCallback(() => {
            pushDataLayer({
                event: 'page_view',
                screen: "グループ一覧",
                path: window.location.pathname,
            })
            if (refGroupList != null && refGroupList.current != null) {
                refGroupList.current.scrollTo({ top: 0 });
            }
        }, []);

        /**
         * useEffect
         */
        React.useEffect(() => {
            if (refGroupList != null && refGroupList.current != null) {
                refGroupList.current.addEventListener("scroll", onScroll);
            }
            getList(0);
            return () => {
                if (refGroupList != null && refGroupList.current != null) {
                    refGroupList.current.removeEventListener(
                        "scroll",
                        onScroll
                    );
                }
            };
        }, []);
        React.useEffect(() => {
            if (props.loginUser != null) {
                getList(0);
            }
        }, [props.loginUser]);

        /**
         * スクロール位置取得
         * @returns
         */
        const getScrollPosition = (): number => {
            if (refGroupList != null && refGroupList.current != null) {
                return Math.max(refGroupList.current.scrollTop);
            } else {
                return 0;
            }
        };

        /**
         * スクロール時
         * @param event
         * @returns
         */
        async function onScroll(event: any) {
            if (timeoutId.current !== 0) {
                return;
            }
            timeoutId.current = window.setTimeout(async function () {
                if (refGroupList == null) {
                    return;
                }
                const el = refGroupList.current;
                if (el == null) {
                    timeoutId.current = 0;
                    return;
                }
                const scrollHeight = el.scrollHeight;
                const scrollY = getScrollPosition();
                const windowHeight = window.innerHeight;
                if (scrollHeight - 50 < scrollY + windowHeight) {
                    if (!hasNext.current) {
                        return;
                    }
                    if (nowFetching.current) {
                        return;
                    }
                    nowFetching.current = true;

                    currentIndex.current =
                        currentIndex.current + readSize.current;
                    await getList(currentIndex.current);

                    nowFetching.current = false;
                }
                timeoutId.current = 0;
            }, 100);
        }

        /**
         * グループ作成
         */
        async function onClickCreateGroup() {
            if (props.loginUser == null || props.loginUser.id === 0) {
                setOpenDialog(true);
                return;
            }
            navigate("/groups/create");
        }

        /**
         * グループクリック時
         * @param group
         */
        async function onClickGroup(group: GroupModel) {
            if (group.id != null) {
                dispatch(SelectedItemAction({ group: group }));
                if (group.join === true) {
                    navigate(`/groups/${group.id}/chat`);
                } else {
                    navigate(`/groups/${group.id}`);
                }
            }
        }
        /**
         * グループリスト取得
         * @returns
         */
        async function getList(index: number) {
            let userId = 0;
            if (props.loginUser != null && props.loginUser.id != null) {
                userId = props.loginUser.id;
            }
            // リクエスト実行
            const result = await GroupRequest.getGroupList(
                userId,
                countryCd,
                index
            );
            if (result == null) {
                if (window.navigator.onLine) {
                    navigate("/maintenance");
                } else {
                    dispatch(NetworkAction({connected: false}));
                }
                return null;
            }
        if (result.rtnCd == null || result.rtnCd < 0) {
                return;
            }
            if (result.hasNext != null) {
                hasNext.current = result.hasNext;
            }
            let wkGroupList: GroupModel[] = [];
            if (index === 0) {
                if (
                    result.lstJoinGroup != null &&
                    props.loginUser != null &&
                    props.loginUser.id != null
                ) {
                    lstJoinGroup.current = result.lstJoinGroup;
                    for (let i = 0; i < lstJoinGroup.current.length; i++) {
                        const joinGroup = lstJoinGroup.current[i];
                        const group = joinGroup.group;
                        if (group != null && group.id != null) {
                            if (joinGroup.underInvitation === 1) {
                                if (joinGroup.userId === props.loginUser.id) {
                                    // 招待を受けている
                                    group.underInvitation = true;
                                }
                            } else if (joinGroup.underApplication === 1) {
                                if (joinGroup.userId === props.loginUser.id) {
                                    // 加入申請をしている
                                    group.applying = true;
                                }
                            } else {
                                group.join = true;
                                if (group.ownerId === props.loginUser.id) {
                                    group.isOwner = true;
                                }
                            }
                            wkGroupList.push(group);
                        }
                    }
                    // setGroupList(wkGroupList);
                }
                if (result.readSize != null) {
                    readSize.current = result.readSize;
                }
            }
            if (result.lstGroup != null) {
                for (let i = 0; i < result.lstGroup.length; i++) {
                    const group = result.lstGroup[i];
                    if (group.id != null) {
                        if (countryCd === "99") {
                            if (group.id <= 148) {
                                continue;
                            }
                        }
                        let exist = false;
                        for (let j = 0; j < lstJoinGroup.current.length; j++) {
                            const joinGroup = lstJoinGroup.current[j];
                            if (group.id === joinGroup.groupId) {
                                exist = true;
                                break;
                            }
                        }
                        if (!exist) {
                            wkGroupList.push(group);
                        }
                    }
                }
                setGroupList((prevGroupList) => {
                    if (index === 0) {
                        return wkGroupList;
                    } else {
                        if (prevGroupList != null) {
                            return prevGroupList.concat(wkGroupList);
                        } else {
                            return wkGroupList;
                        }
                    }
                });
            }

            if (index === 0) {
                setInitialDataLoaded(true);
            }
        }

        /**
         * レンダリング
         */
        return (
            <Box className="pageWrapper GroupList" ref={refGroupList}>
                <Box className="group-list-area">
                    {initialDataLoaded &&
                        lstGroup !== undefined &&
                        lstGroup.map((group, index) => {
                            return (
                                <div key={index} className="item-wrapper">
                                    <GroupListItem
                                        key={group.id}
                                        group={group}
                                        selected={group.selected}
                                        onClick={() => onClickGroup(group)}
                                    />
                                </div>
                            );
                        })}
                    {/* <Zoom in={trigger}>
                        <div className="scroll-top" role="presentation">
                            <Fab
                                onClick={scrollToTop}
                                color="primary"
                                size="small"
                                aria-label="scroll back to top"
                            >
                                <KeyboardArrowUp />
                            </Fab>
                        </div>
                    </Zoom> */}
                </Box>
                <img
                    className="img-create-group"
                    src={AddIcon}
                    alt="Create Group Button"
                    onClick={onClickCreateGroup}
                />
                <Dialog
                    className="dialog"
                    open={openDialog}
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        className="dialog-title"
                    >
                        <FormattedMessage id={"dlg_title_message"} />
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            padding: {
                                xs: "5px",
                                sm: "10px",
                            },
                            paddingTop: {
                                xs: "5px !important",
                                sm: "10px !important",
                            },
                        }}
                    >
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"msg_login_needed"} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="dialog-button"
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    },
    (prevProps: Props, nextProps: Props) => {
        if (prevProps.loginUser !== nextProps.loginUser) {
            return false;
        }
        return true;
    }
);
export default GroupList;
